import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TireReportOrdering } from 'types/tiresReport';
import { SortOrder } from 'utils/tableUtils';

interface FuelReportState {
  orderBy: TireReportOrdering;
  order: SortOrder;
  page: number;
  size: number;
  resourceFilters: string[];
  driverFilters: string[];
  licenceFilters: string[];
}

const initialState: FuelReportState = {
  orderBy: '-created_at',
  order: 'desc',
  page: 1,
  size: 10,
  resourceFilters: [],
  driverFilters: [],
  licenceFilters: [],
};

export const fuelReportSlice = createSlice({
  name: 'fuelReport',
  initialState,
  reducers: {
    updateFuelOrderBy: (state, action: PayloadAction<TireReportOrdering>) => {
      state.orderBy = action.payload;
      state.order = action.payload.startsWith('-') ? 'desc' : 'asc';
    },
    updateFuelPage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    updateFuelSize: (state, action: PayloadAction<number>) => {
      state.size = action.payload;
    },
    updateFuelDriverFilters: (state, action: PayloadAction<string[]>) => {
      state.driverFilters = action.payload;
    },
    updateFuelResourceFilters: (state, action: PayloadAction<string[]>) => {
      state.resourceFilters = action.payload;
    },
    updateFuelLicenceFilters: (state, action: PayloadAction<string[]>) => {
      state.licenceFilters = action.payload;
    },

    removeFuelFilters: (state) => {
      state.driverFilters = [];
      state.resourceFilters = [];
      state.licenceFilters = [];
    },
  },
});

export const {
  updateFuelOrderBy,
  updateFuelDriverFilters,
  updateFuelLicenceFilters,
  updateFuelPage,
  updateFuelResourceFilters,
  updateFuelSize,
  removeFuelFilters,
} = fuelReportSlice.actions;

export const fuelReportReducer = fuelReportSlice.reducer;
