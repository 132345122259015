import { createApi } from '@reduxjs/toolkit/query/react';
import cookies from 'browser-cookies';
import {
  LOGGED_IN_COOKIE,
  RESOURCES_FOR_GROUPS_PAGE_SIZE,
  UNASSIGNED_PAGE_SIZE,
  UNASSIGNED_TASKS,
} from 'constants/common';
import { omit, sortBy } from 'lodash';
import {
  logout,
  setCredentials,
  setIsAdmin,
  setIsLoggedIn,
} from 'store/reducers/authSlice';
import { setUnassignedShipment } from 'store/reducers/orderSlice';
import {
  AddAddressApi,
  Address,
  AddTerminalApi,
  AllRoutesApiResponse,
  AllRoutesMapPointDetailsApi,
  AvailableDatesForWorkshifts,
  AvailableWorkshiftOnDate,
  Booking,
  Chat,
  ChatMessageStatusRequest,
  CommonOptimizationSettings,
  CompanyImage,
  Driver,
  DriverDevice,
  DriverHistoricalPath,
  Errand,
  GroupApiResponse,
  GroupDetailApiResponse,
  GroupingResourcesWithPagination,
  LoginResponse,
  ManagerInfoApi,
  QuickReply,
  ResourceApi,
  ResourceSettingsApiResponse,
  ResourcesSettings,
  SearchApiResponse,
  SendingMessage,
  SetTimeGapRequest,
  SettingsWithPaginationParams,
  Shipment,
  ShipmentStatuses,
  Subcontractor,
  SubcontractorDetail,
  Terminal,
  TerminalErrand,
  UnassignedShipments,
  UnreadMessagesByResource,
  WorkshiftHistoryItem,
} from 'types/api';

import { setChatList, setUnreadMessages } from '../reducers/chatSlice';
import { setDriversList } from '../reducers/driverSlice';
import {
  removeSelectedResourceId,
  setResourcesList,
} from '../reducers/resourceSlice';

import { customFetchBase } from './customFetchBase';
import {
  ChangePackageMutation,
  CreateBookingMutation,
  CreateErrandMutation,
  CreateShipmentMutation,
  GetAddressByStreetAndCityNamesQuery,
  UpdateBookingMutation,
  UpdateErrandMutation,
  UpdateShipmentMutation,
} from './types';
import { setTotalResourcesSettings } from 'store/reducers/settingsSlice';
import { formSearchParamsStr } from 'utils/urlUtils';
import { RootState } from 'store/index';
import { TerminalScanResponseApi } from 'types/terminalScanChart';
import { ResourceWithPosition } from 'types/resource';

export interface LoginRequest {
  email: string;
  password: string;
  remember_me: boolean;
  requiredAdmin: boolean;
}

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: customFetchBase,
  tagTypes: [
    'Shipment',
    'Me',
    'Subcontractors',
    'Company',
    'DriverDevice',
    'ResourcesForGroups',
    'Groups',
    'GroupsById',
    'GroupedTerminalErrands',
  ],
  endpoints: (builder) => {
    return {
      loginUser: builder.mutation<LoginResponse, LoginRequest>({
        query(data) {
          return {
            url: 'traffic-manager/sign-in/',
            method: 'POST',
            body: omit(data, ['requiredAdmin']),
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            // TODO: Set loading spinner
            const { data } = await queryFulfilled;

            if (args.requiredAdmin && args.requiredAdmin !== data.is_admin) {
              throw new Error("User don't have Admin role");
            }

            await dispatch(setIsAdmin(args.requiredAdmin));
            await dispatch(setIsLoggedIn());
            // eslint-disable-line
            cookies.set(LOGGED_IN_COOKIE, 'true');
          } catch (error) {
            // TODO: Set error popup
            console.log(error);
          }
        },
        invalidatesTags: (result, error, arg) =>
          arg.requiredAdmin && arg.requiredAdmin !== result?.is_admin
            ? []
            : [{ type: 'Me' }],
      }),
      logoutUser: builder.mutation<void, void>({
        query() {
          return {
            url: 'traffic-manager/sign-out/',
            method: 'POST',
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            await queryFulfilled;
            await cookies.erase(LOGGED_IN_COOKIE);
            await dispatch(logout());
          } catch (error) {
            console.log(error);
          }
        },
      }),
      TerminalGroupedErrandsByResourceId: builder.query<
        TerminalErrand[],
        { resourceId: string; workshiftId: string | null }
      >({
        query(data) {
          let workshiftParam = '';

          if (data.workshiftId) {
            workshiftParam = `?work_shift_id=${data.workshiftId}`;
          }
          return {
            url: `errand/terminal-grouped/${data.resourceId}${workshiftParam}`,
            credentials: 'include',
          };
        },
        providesTags: (result, error, arg) => [
          { type: 'GroupedTerminalErrands', arg },
        ],
      }),

      shipmentsByResourceId: builder.query<
        Shipment[],
        { resourceId: string; workshiftId: string | null }
      >({
        query(data) {
          let workshiftParam = '';

          if (data.workshiftId) {
            workshiftParam = `?work_shift_id=${data.workshiftId}`;
          }
          return {
            url: `shipment/by-resource/${data.resourceId}${workshiftParam}`,
            credentials: 'include',
          };
        },
        onQueryStarted: async (args, { queryFulfilled, dispatch }) => {
          dispatch(
            apiSlice.util.invalidateTags([{ type: 'GroupedTerminalErrands' }]),
          );

          await queryFulfilled;
        },
        providesTags: (result, error, arg) => [{ type: 'Shipment', arg }],
      }),

      subcontractors: builder.query<
        Subcontractor[],
        { country?: string; city?: string; search?: string }
      >({
        query: (args) => {
          const params: { country?: string; city?: string; search?: string } =
            {};
          const { country, city, search } = args;

          if (country) {
            params.country = country;
          }

          if (city) {
            params.city = city;
          }

          if (search) {
            params.search = search;
          }

          return {
            url: 'subcontractor/',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: ['Subcontractors'],
      }),

      companiesCountries: builder.query<string[], { country?: string }>({
        query: (args) => {
          const params: { country?: string } = {};
          const { country } = args;

          if (country) {
            params.country = country;
          }

          return {
            url: 'subcontractor/countries',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      companiesCities: builder.query<
        string[],
        { country?: string; city?: string }
      >({
        query: (args) => {
          const params: { country?: string; city?: string } = {};
          const { country, city } = args;

          if (country) {
            params.country = country;
          }

          if (city) {
            params.city = city;
          }

          return {
            url: 'subcontractor/cities',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      addCompany: builder.mutation<
        SubcontractorDetail,
        { name: string; city: string; country: string; email?: string }
      >({
        query(args) {
          return {
            url: 'subcontractor/',
            method: 'POST',
            body: args,
          };
        },
        invalidatesTags: ['Subcontractors'],
      }),

      devicesByCompanyId: builder.query<DriverDevice[], string>({
        query(companyId) {
          return {
            url: `subcontractor/${companyId}/device`,
            credentials: 'include',
          };
        },
        providesTags: (result, error, arg) => [{ type: 'DriverDevice', arg }],
      }),

      editDevicesByCompanyId: builder.mutation<
        DriverDevice,
        { deviceId: string; companyId: string } & Partial<
          Omit<DriverDevice, 'id'>
        >
      >({
        query({ deviceId, companyId, is_active, notes }) {
          const body: { is_active?: boolean; notes?: string } = {};

          if (typeof is_active === 'boolean') {
            body.is_active = is_active;
          }

          if (typeof notes === 'string') {
            body.notes = notes;
          }

          return {
            url: `subcontractor/${companyId}/device/${deviceId}`,
            method: 'PATCH',
            body,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'DriverDevice', arg: arg.companyId },
        ],
      }),

      deactivateAllDevicesByCompanyId: builder.mutation<DriverDevice, string>({
        query(companyId) {
          return {
            url: `subcontractor/${companyId}/deactivate-all-devices`,
            method: 'POST',
            body: {},
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'DriverDevice', arg },
        ],
      }),

      companyById: builder.query<SubcontractorDetail, string>({
        query(companyId) {
          return {
            url: `subcontractor/${companyId}`,
            credentials: 'include',
          };
        },
        providesTags: (result, error, arg) => [{ type: 'Company', arg }],
      }),

      editCompanyById: builder.mutation<
        SubcontractorDetail,
        Partial<Omit<SubcontractorDetail, 'created_by' | 'created_at'>>
      >({
        query: ({ id, ...rest }) => ({
          url: `/subcontractor/${id}`,
          method: 'PATCH',
          body: rest,
        }),
        invalidatesTags: (result, error, arg) => [
          'Subcontractors',
          { type: 'Company', arg: arg.id },
        ],
      }),

      deleteCompanyById: builder.mutation<SubcontractorDetail, string>({
        query: (id) => ({
          url: `/subcontractor/${id}`,
          method: 'DELETE',
          body: {},
        }),
        invalidatesTags: ['Subcontractors'],
      }),

      newQrCodeCompanyById: builder.mutation<SubcontractorDetail, string>({
        query: (id) => ({
          url: `/subcontractor/${id}/generate-new-qr-code`,
          method: 'PATCH',
          body: {},
        }),
        invalidatesTags: (result, error, arg) => [
          { type: 'Company', companyId: arg },
        ],
      }),

      sendCompanyEmail: builder.mutation<
        SubcontractorDetail,
        { id: string; email: string }
      >({
        query: ({ id, email }) => ({
          url: `/subcontractor/${id}/send-email-with-qr-code`,
          method: 'POST',
          body: {},
          params: {
            email,
          },
        }),
      }),

      moveShipmentToCurrentWorkshift: builder.mutation<
        Shipment,
        { shipmentId: string; resourceId: string; workshiftId: string | null }
      >({
        query: ({ shipmentId }) => ({
          url: `/shipment/${shipmentId}/move-to-current-work-shift`,
          method: 'PATCH',
          body: {},
        }),
        invalidatesTags: (result, error, arg) => [
          {
            type: 'Shipment',
            arg: {
              resourseId: arg.resourceId,
              workshiftId: arg.workshiftId,
            },
          },
        ],
      }),

      setPickedupStatusForTerminalTasks: builder.mutation<
        string,
        { ids: string[]; resourceId: string; workshiftId: string | null }
      >({
        query: ({ ids }) => ({
          url: `/management/set-scanned-parcels-picked-up/`,
          method: 'POST',
          body: ids,
        }),
        invalidatesTags: (result, error, arg) => [
          {
            type: 'Shipment',
            arg: {
              resourseId: arg.resourceId,
              workshiftId: arg.workshiftId,
            },
          },
        ],
      }),

      setShipmentStatusManually: builder.mutation<
        string,
        {
          ids: string[];
          resourceId: string;
          workshiftId: string | null;
          status: ShipmentStatuses;
        }
      >({
        query: ({ ids, status }) => ({
          url: `/management/set-multiple-shipments/${encodeURIComponent(
            status,
          )}/`,
          method: 'POST',
          body: ids,
        }),
        invalidatesTags: (result, error, arg) => [
          {
            type: 'Shipment',
            arg: {
              resourseId: arg.resourceId,
              workshiftId: arg.workshiftId,
            },
          },
        ],
      }),

      workshiftsByResourceId: builder.query<
        WorkshiftHistoryItem[],
        { resource_id: string; work_shift_id?: string; search_date?: string }
      >({
        query({ resource_id, work_shift_id, search_date }) {
          return {
            url: 'work-shift/history',
            params: { resource_id, work_shift_id, search_date },
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      availableDatesForWorkshifts: builder.query<
        AvailableWorkshiftOnDate[],
        { resource_id: string; latest_date?: string }
      >({
        query({ resource_id, latest_date }) {
          return {
            url: 'work-shift/history/available-dates',
            params: { resource_id, latest_date },
            credentials: 'include',
          };
        },
        transformResponse(
          response: AvailableDatesForWorkshifts,
        ): Promise<AvailableWorkshiftOnDate[]> | AvailableWorkshiftOnDate[] {
          return sortBy(
            Object.entries(response).map(([date, workshifts]) => ({
              date,
              workshifts,
            })),
            'date',
          );
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      unassignedShipments: builder.query<UnassignedShipments, number>({
        // query(page) {
        //   return {
        //     url: `shipment/unassigned?page=${page}&size=${UNASSIGNED_PAGE_SIZE}`,
        //     credentials: 'include',
        //   };
        // },
        // @ts-ignore
        async queryFn(page, queryApi, extraOptions, baseQuery) {
          // Accessing the store state
          const state: RootState = queryApi.getState() as RootState;

          let url = `shipment/unassigned?page=${page}&size=${UNASSIGNED_PAGE_SIZE}`;

          const shipmentId = state.order.searchedUnassignedShipmentId;

          if (shipmentId) {
            url = `${url}&current_shipment_id=${shipmentId}`;
          }

          const result = await baseQuery(url);
          return result;
        },
        keepUnusedDataFor: 0,
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setUnassignedShipment(data));
          } catch (error) {
            dispatch(removeSelectedResourceId());
            console.log(error);
          }
        },
      }),

      managerInfo: builder.query<ManagerInfoApi, void>({
        query() {
          return {
            url: 'traffic-manager/me/',
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;

            dispatch(setCredentials(omit(data, ['is_admin'])));
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: ['Me'],
      }),

      companyImages: builder.query<CompanyImage[], void>({
        query() {
          return {
            url: 'company-image/',
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      searchByParams: builder.query<SearchApiResponse, string>({
        query(q) {
          return {
            url: 'search/strict',
            credentials: 'include',
            params: { q },
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      mapAllRoutes: builder.query<
        AllRoutesApiResponse[],
        {
          date: string;
          hide_historical?: boolean;
          group?: string[];
          resource_name?: string[];
        }
      >({
        query({
          date,
          hide_historical = false,
          group = [],
          resource_name = [],
        }) {
          const params = new URLSearchParams();
          params.append('date', date);
          params.append('hide_historical', hide_historical ? 'true' : 'false');
          if (group.length > 0) {
            group.forEach((g) => params.append('group', g));
          }

          if (resource_name.length > 0) {
            resource_name.forEach((r) => params.append('resource_name', r));
          }

          return {
            url: `shipment/all-resources?${params}`,
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      allRoutesMapPointDetails: builder.mutation<
        AllRoutesMapPointDetailsApi,
        { work_shift_id: string; errands_ids: string[] }
      >({
        query: (payload) => ({
          url: '/shipment/all-resources/details',
          method: 'POST',
          body: payload,
        }),
      }),

      allHubs: builder.query<Terminal[], void>({
        query() {
          return {
            url: 'hubs/',
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      updateBooking: builder.mutation<Booking, UpdateBookingMutation>({
        query: (payload) => ({
          url: `/booking/${payload.id}`,
          method: 'PUT',
          body: payload,
        }),
      }),

      addAddress: builder.mutation<AddAddressApi, AddAddressApi>({
        query: (payload) => ({
          url: '/address/',
          method: 'POST',
          body: payload,
        }),
      }),

      addTerminal: builder.mutation<AddTerminalApi, AddTerminalApi>({
        query: (payload) => ({
          url: '/hubs/',
          method: 'POST',
          body: payload,
        }),
      }),

      createBooking: builder.mutation<Booking, CreateBookingMutation>({
        query: (payload) => ({
          url: '/booking/',
          method: 'POST',
          body: payload,
        }),
      }),

      updateErrand: builder.mutation<Errand, UpdateErrandMutation>({
        query: (payload) => ({
          url: `/errand/${payload.id}`,
          method: 'PUT',
          body: payload,
          headers: {
            'skip-push-notification': 'true',
          },
        }),
      }),

      createErrand: builder.mutation<Errand, CreateErrandMutation>({
        query: (payload) => ({
          url: '/errand/',
          method: 'POST',
          body: payload,
        }),
      }),

      updateShipment: builder.mutation<
        UpdateShipmentMutation,
        UpdateShipmentMutation
      >({
        query: ({ status, id, work_shift_id }) => ({
          url: `/shipment/${id}`,
          method: 'PATCH',
          body: { status, work_shift_id },
        }),
      }),

      createShipment: builder.mutation<Shipment, CreateShipmentMutation>({
        query: (payload) => ({
          url: '/shipment/',
          method: 'POST',
          body: payload,
        }),
      }),

      startCityFlowOptimization: builder.mutation<void, string>({
        query: (resourceId) => ({
          url: `/resource/${resourceId}/optimize-route/cityflow`,
          method: 'POST',
          credentials: 'include',
        }),
      }),

      startHereSDKOptimization: builder.mutation<void, string>({
        query: (resourceId) => ({
          url: `/resource/${resourceId}/optimize-route/here_sdk`,
          method: 'POST',
          credentials: 'include',
        }),
      }),

      updatePackages: builder.mutation<string, ChangePackageMutation>({
        query: ({ packages, bookingId }) => ({
          url: `/booking/${bookingId}/packages`,
          method: 'PUT',
          body: packages,
        }),
      }),

      updateQuickReplyByMessageId: builder.mutation<
        string,
        { messageId: string; quickReplies: number[] }
      >({
        query: ({ messageId, quickReplies }) => {
          const serializedReplies = quickReplies.map((id) => ({ id }));
          return {
            url: `/chat-message/${messageId}/quick_responses`,
            method: 'PUT',
            body: serializedReplies,
          };
        },
      }),

      unreadMessagesByResource: builder.query<UnreadMessagesByResource[], void>(
        {
          query() {
            return {
              url: 'chat-message/unreads-by-resource',
              credentials: 'include',
            };
          },
          async onQueryStarted(args, { dispatch, queryFulfilled }) {
            try {
              const { data } = await queryFulfilled;
              dispatch(setUnreadMessages(data));
            } catch (error) {
              dispatch(removeSelectedResourceId());
              console.log(error);
            }
          },
        },
      ),

      sendChatMessage: builder.mutation<{ id: string }, SendingMessage>({
        query(data) {
          return {
            url: 'chat-message/',
            method: 'POST',
            body: data,
            credentials: 'include',
          };
        },
      }),

      setChatMessageStatus: builder.mutation<string, ChatMessageStatusRequest>({
        query(data) {
          return {
            url: 'chat-message/read',
            method: 'PATCH',
            body: data.messagesIds,
            credentials: 'include',
            // eslint-disable-next-line camelcase
            params: { resource_id: data.resource_id },
          };
        },
      }),

      setTimeGap: builder.mutation<string, SetTimeGapRequest>({
        query({ resource_id, time_gap }) {
          return {
            url: `resource/${resource_id}/set_time_gap/`,
            method: 'PATCH',
            credentials: 'include',
            // eslint-disable-next-line camelcase
            params: { time_gap },
          };
        },
      }),

      resourcesWithColors: builder.query<ResourceApi[], void>({
        query: () => {
          return {
            url: `resource/colors`,
            method: 'GET',
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setResourcesList(data));
          } catch (error) {
            console.log(error);
          }
        },
      }),

      resourcesWithPositions: builder.query<ResourceWithPosition[], void>({
        query: () => {
          return {
            url: 'resource/positions',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      resources: builder.query<ResourceApi[], string | void>({
        query: (param) => {
          let url = 'resource/';

          if (param) {
            url = `${url}?resource_name=${param}`;
          }

          return {
            url,
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setResourcesList(data));

            dispatch(apiSlice.endpoints.resourcesWithColors.initiate());
          } catch (error) {
            console.log(error);
          }
        },
      }),

      resourcesV2: builder.query<
        ResourceApi[],
        { resource_name?: string; group?: string[]; date?: string }
      >({
        query: (params) => {
          let url = 'resource/v2/';

          return {
            url,
            params,
          };
        },
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setResourcesList(data));

            // dispatch(apiSlice.endpoints.resourcesWithColors.initiate());
          } catch (error) {
            console.log(error);
          }
        },
      }),

      resourcesForGroups: builder.query<
        ResourceApi[],
        { resource_name?: string }
      >({
        query: ({ resource_name }) => ({
          url: 'resource/',
          params: {
            resource_name,
          },
        }),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),
      // Groups for settings screen
      getAllGroups: builder.query<
        GroupApiResponse[],
        { title?: string; resource_id?: string }
      >({
        query: ({ title, resource_id }) => ({
          url: 'resource/group',
          params: {
            title,
            resource_id,
          },
        }),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: (result, error, arg) => [{ type: 'Groups', arg }],
      }),

      getGroupById: builder.query<GroupDetailApiResponse, string>({
        query: (groupId) => ({
          url: `resource/group/${groupId}`,
        }),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
        providesTags: (result, error, arg) => [{ type: 'GroupsById', arg }],
      }),

      addGroup: builder.mutation<
        GroupDetailApiResponse,
        { title: string; description: string }
      >({
        query(args) {
          return {
            url: 'resource/group',
            method: 'POST',
            body: args,
          };
        },
        invalidatesTags: ['Groups'],
      }),

      addResourcesToGroup: builder.mutation<
        GroupDetailApiResponse,
        { groupId: string; resourcesIds: string[] }
      >({
        query({ groupId, resourcesIds }) {
          return {
            url: `resource/group/${groupId}/add-resources`,
            method: 'PATCH',
            body: resourcesIds,
          };
        },
        invalidatesTags: (result, error, arg) => [
          { type: 'GroupsById', arg: arg.groupId },
          'ResourcesForGroups',
        ],
      }),

      enableResourcesInGroup: builder.mutation<
        GroupDetailApiResponse,
        { groupId: string; resourcesIds: string[] }
      >({
        query({ groupId, resourcesIds }) {
          return {
            url: `resource/group/${groupId}/enable-resources`,
            method: 'PATCH',
            body: resourcesIds,
          };
        },
        invalidatesTags: ['Groups', 'ResourcesForGroups'],
      }),

      disableResourcesInGroup: builder.mutation<
        GroupDetailApiResponse,
        { groupId: string; resourcesIds: string[] }
      >({
        query({ groupId, resourcesIds }) {
          return {
            url: `resource/group/${groupId}/disable-resources`,
            method: 'PATCH',
            body: resourcesIds,
          };
        },
        invalidatesTags: ['Groups', 'ResourcesForGroups'],
      }),

      removeResourcesFromGroup: builder.mutation<
        string,
        { groupId: string; resourcesIds: string[] }
      >({
        query({ groupId, resourcesIds }) {
          return {
            url: `resource/group/${groupId}/remove-resources`,
            method: 'PATCH',
            body: resourcesIds,
          };
        },
        invalidatesTags: ['Groups', 'ResourcesForGroups'],
      }),

      editGroupDetail: builder.mutation<
        string,
        { groupId: string; title: string; description: string }
      >({
        query({ groupId, title, description }) {
          return {
            url: `resource/group/${groupId}`,
            method: 'PATCH',
            body: {
              title,
              description,
            },
          };
        },
        invalidatesTags: ['Groups'],
      }),

      deleteGroup: builder.mutation<string, string>({
        query(groupId) {
          return {
            url: `resource/group/${groupId}`,
            method: 'DELETE',
          };
        },
      }),

      resourcesForGroupsWithPagination: builder.query<
        GroupingResourcesWithPagination,
        { number?: string; page?: number; size?: number }
      >({
        query: ({
          number,
          size = RESOURCES_FOR_GROUPS_PAGE_SIZE,
          page = 1,
        }) => ({
          url: 'resource/group/all-resources',
          params: {
            number,
            page,
            size,
          },
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      resourcesSettings: builder.query<
        { results: ResourcesSettings | null; total: number },
        SettingsWithPaginationParams
      >({
        query: ({ page = 1, size = 50, current_resource_id, ...other }) => {
          const queryParams: SettingsWithPaginationParams = {
            page,
            size,
            ...other,
          };

          if (current_resource_id && current_resource_id !== UNASSIGNED_TASKS) {
            queryParams.current_resource_id = current_resource_id;
          }

          const searchParams = formSearchParamsStr(queryParams);

          const paramsStr = searchParams ? `?${searchParams}` : '';
          return {
            url: `resource/settings${paramsStr}`,
          };
        },

        // @ts-ignore
        async onQueryStarted(args, { queryFulfilled, dispatch }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },

        transformResponse(response: ResourceSettingsApiResponse): {
          results: ResourcesSettings | null;
          total: number;
        } {
          let data: { results: ResourcesSettings | null; total: number } = {
            results: null,
            total: 0,
          };

          if ((response as ResourceSettingsApiResponse)?.items?.length) {
            data = {
              total: response.total,
              results: (response as ResourceSettingsApiResponse)?.items?.reduce(
                (accum, curr): ResourcesSettings => {
                  return { ...accum, [curr.number]: omit(curr, 'number') };
                },
                {},
              ),
            };
          }

          return data;
        },
        providesTags: ['ResourcesForGroups'],
      }),

      resourcesSettingsPageData: builder.query<
        ResourceSettingsApiResponse | null,
        {
          page?: number;
          size?: number;
          current_resource_id?: string;
          resource_number?: string[];
          group?: string[];
        }
      >({
        query: ({ page = 1, size = 50, ...other }) => {
          const queryParams = {
            page,
            size,
            ...other,
          };
          const searchParams = formSearchParamsStr(queryParams);

          const paramsStr = searchParams ? `?${searchParams}` : '';
          return {
            url: `resource/settings${paramsStr}`,
          };
        },

        async onQueryStarted(args, { queryFulfilled, dispatch }) {
          try {
            const { data } = await queryFulfilled;

            dispatch(setTotalResourcesSettings(data?.total || 0));
          } catch (error) {
            console.log(error);
          }
        },
      }),

      commonOptimizationSettings: builder.query<
        CommonOptimizationSettings,
        void
      >({
        query: () => ({
          url: 'settings/optimization',
        }),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      updateResourceSettings: builder.mutation<string, ResourcesSettings>({
        query(data) {
          return {
            url: 'resource/settings',
            method: 'PUT',
            body: data,
            credentials: 'include',
          };
        },
      }),

      updateCommonOptSettings: builder.mutation<
        string,
        {
          commonSettings: CommonOptimizationSettings;
          resourceId: string;
          workshiftId: string | null;
        }
      >({
        query({ commonSettings }) {
          return {
            url: 'settings/optimization',
            method: 'PUT',
            body: commonSettings,
            credentials: 'include',
          };
        },
        invalidatesTags: (result, error, arg) => [
          {
            type: 'Shipment',
            arg: {
              resourseId: arg.resourceId,
              workshiftId: arg.workshiftId,
            },
          },
        ],
      }),

      driverByResourceId: builder.query<Driver, string>({
        query: (resourceId) => ({
          url: `driver/${resourceId}/`,
          credentials: 'include',
        }),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      drivers: builder.query<Driver[], void>({
        query: () => ({
          url: 'driver/',
          credentials: 'include',
        }),
        async onQueryStarted(args, { dispatch, queryFulfilled }) {
          try {
            const { data } = await queryFulfilled;
            dispatch(setDriversList(data));
          } catch (error) {
            console.log(error);
          }
        },
      }),

      quickResponses: builder.query<QuickReply[], void>({
        query: () => ({
          url: 'chat-message/quick_responses',
          credentials: 'include',
        }),
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      getStreetsGroupedByCity: builder.query<
        GetAddressByStreetAndCityNamesQuery[],
        string
      >({
        query(street) {
          return {
            url: `address/by-street/${street}/streets-grouped`,
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      getAddressByStreetAndCityNames: builder.query<
        Address[],
        GetAddressByStreetAndCityNamesQuery
      >({
        query({ street, city }) {
          return {
            url: `address/by?street=${street}&city=${city}`,
            credentials: 'include',
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      driverHistoricalPath: builder.query<
        DriverHistoricalPath[],
        { resource_id: string; work_shift_id: string }
      >({
        query(params) {
          return {
            url: 'driver/historical-path/',
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),

      terminalScansChart: builder.query<
        TerminalScanResponseApi[],
        { work_shift_id: string }
      >({
        query(params) {
          return {
            url: 'errand/scans/',
            credentials: 'include',
            params,
          };
        },
        async onQueryStarted(args, { queryFulfilled }) {
          try {
            await queryFulfilled;
          } catch (error) {
            console.log(error);
          }
        },
      }),
    };
  },
});

// TODO: Add get/add/update keyword to query/mutation names (e.g useResourcesQuery => useGetResourcesQuery)
export const {
  useLoginUserMutation,
  useLogoutUserMutation,
  useShipmentsByResourceIdQuery,
  useUpdateBookingMutation,
  useCreateBookingMutation,
  useUpdateErrandMutation,
  useCreateErrandMutation,
  useUpdateShipmentMutation,
  useCreateShipmentMutation,
  useUpdatePackagesMutation,
  useResourcesQuery,
  useGetAddressByStreetAndCityNamesQuery,
  useGetStreetsGroupedByCityQuery,
  useUnassignedShipmentsQuery,
  useManagerInfoQuery,
  useAddAddressMutation,
  useCompanyImagesQuery,
  useStartCityFlowOptimizationMutation,
  useStartHereSDKOptimizationMutation,
  useAllHubsQuery,
  useAddTerminalMutation,
  useResourcesSettingsQuery,
  useCommonOptimizationSettingsQuery,
  useUpdateCommonOptSettingsMutation,
  useUpdateResourceSettingsMutation,
  useSetTimeGapMutation,
  useSearchByParamsQuery,
  useAvailableDatesForWorkshiftsQuery,
  useWorkshiftsByResourceIdQuery,
  useMoveShipmentToCurrentWorkshiftMutation,
  useSubcontractorsQuery,
  useCompanyByIdQuery,
  useDeleteCompanyByIdMutation,
  useEditCompanyByIdMutation,
  useNewQrCodeCompanyByIdMutation,
  useSendCompanyEmailMutation,
  useDevicesByCompanyIdQuery,
  useEditDevicesByCompanyIdMutation,
  useDeactivateAllDevicesByCompanyIdMutation,
  useAddCompanyMutation,
  useCompaniesCitiesQuery,
  useCompaniesCountriesQuery,
  useResourcesForGroupsWithPaginationQuery,
  useAddResourcesToGroupMutation,
  useAddGroupMutation,
  useDisableResourcesInGroupMutation,
  useEnableResourcesInGroupMutation,
  useDeleteGroupMutation,
  useGetAllGroupsQuery,
  useGetGroupByIdQuery,
  useRemoveResourcesFromGroupMutation,
  useEditGroupDetailMutation,
  useDriverByResourceIdQuery,
  useTerminalGroupedErrandsByResourceIdQuery,
  useMapAllRoutesQuery,
  useAllRoutesMapPointDetailsMutation,
  useResourcesV2Query,
  useDriverHistoricalPathQuery,
  useTerminalScansChartQuery,
  useSetPickedupStatusForTerminalTasksMutation,
  useResourcesWithPositionsQuery,
  useSetShipmentStatusManuallyMutation,
} = apiSlice;
