import { FC, useMemo, useState } from 'react';
import NumberedListIcon from '@mui/icons-material/FormatListNumbered';
import { IconButton } from '@mui/material';
import { LOCAL_STORAGE_KEYS, UNASSIGNED_TASKS } from 'constants/common';
import { useIsManualOrderSetting } from 'hooks/useIsManualOrderSetting';
import { useOrdersList } from 'hooks/useOrdersList';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { getUnassignedCurrentPage } from 'store/selectors/order';
import { getIsCurrentResourceHasWorkshift } from 'store/selectors/resource';
import { getOnlyCurrentWorkshiftSelector } from 'store/selectors/settings';
import {
  getIsCurrentOrFutureWorkshiftSelector,
  getIsCurrentWorkshiftSelector,
  getSelectedWorkshiftIdSelector,
} from 'store/selectors/workshift';
import { t } from 'ttag';

import { ChangeGridButton } from 'components/ui/ChangeGridButton';
import { ChooseWorkshiftButton } from 'components/ui/ChooseWorkshiftButton';
import { CityFlowButton } from 'components/ui/CityFlowButton';
import { CreateOrderButton } from 'components/ui/CreateOrderButton';
import { HereOptButton } from 'components/ui/HereOptButton';
import { OpenSettingsButton } from 'components/ui/OpenSettingsButton';
import { Section } from 'components/ui/Section';

import { OrdersSectionList } from '../OrdersSectionList';
import { OrdersSectionSkeleton } from '../OrdersSectionSkeleton';

import { styles } from './styles';

interface OrdersSectionProps {
  selectedOrderId?: string | null;
  selectedResourceId: string | null;
  halfSize: boolean;
}

export const OrdersSection: FC<OrdersSectionProps> = ({
  selectedOrderId,
  selectedResourceId,
  halfSize = false,
}) => {
  const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const unassignedCurrentPage = useAppSelector(getUnassignedCurrentPage);
  const isCurrentResourceHasWorkshift = useAppSelector(
    getIsCurrentResourceHasWorkshift(selectedResourceId),
  );
  const isCurrentWorkshift = useAppSelector(getIsCurrentWorkshiftSelector);

  const isCurrentOrFutureWorkshift = useAppSelector(
    getIsCurrentOrFutureWorkshiftSelector,
  );
  const { ordersList, isFetching, unassignedIsFetching } = useOrdersList(
    unassignedCurrentPage,
  );

  const [showOrderNumber, setShowOrderNumber] = useState(
    localStorage.getItem(LOCAL_STORAGE_KEYS.showOrderNumber) === 'true',
  );
  const { isManualOptimizationSetting } = useIsManualOrderSetting();

  const setShowOrderNumberHandler = () => {
    setShowOrderNumber((prevValue) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.showOrderNumber, `${!prevValue}`);
      return !prevValue;
    });
  };

  // useEffect(() => {
  //   unassignedRefetch();
  // }, [ordersList]);

  const content = useMemo(() => {
    if (
      !onlyCurrentWorkshift &&
      !selectedWorkshiftId &&
      selectedResourceId !== UNASSIGNED_TASKS
    ) {
      return null;
    }

    if (isFetching) {
      return <OrdersSectionSkeleton quantity={10} />;
    }

    if (ordersList?.length) {
      return (
        <OrdersSectionList
          ordersList={ordersList}
          selectedResourceId={selectedResourceId}
          showOrderNumber={showOrderNumber}
          selectedOrderId={selectedOrderId}
          unassignedIsFetching={unassignedIsFetching}
        />
      );
    }

    return null;
  }, [
    ordersList,
    isFetching,
    selectedOrderId,
    showOrderNumber,
    unassignedIsFetching,
    onlyCurrentWorkshift,
    selectedWorkshiftId,
  ]);

  const sectionStyle = halfSize ? styles.halfContainer : styles.container;

  const OrderNumberButton = (
    <IconButton
      key="change-order-list-number-button"
      sx={styles.iconButton}
      onClick={setShowOrderNumberHandler}
    >
      <NumberedListIcon sx={styles.icon} />
    </IconButton>
  );

  const buttons = useMemo(() => {
    if (onlyCurrentWorkshift) {
      if (!isCurrentOrFutureWorkshift) {
        return [
          <ChangeGridButton key="change-grid-button" />,
          OrderNumberButton,
          <ChooseWorkshiftButton key="choose-workshift-button" />,
          <OpenSettingsButton key="open-settings-button" />,
        ];
      }

      return [
        <CreateOrderButton key="create-order-button" />,
        <ChangeGridButton key="change-grid-button" />,
        OrderNumberButton,
        <ChooseWorkshiftButton key="choose-workshift-button" />,
        <OpenSettingsButton key="open-settings-button" />,
        <HereOptButton
          key="here-sdk-optimize-button"
          disabled={!isManualOptimizationSetting}
        />,
        <CityFlowButton
          key="city-flow-optimize-button"
          disabled={!isManualOptimizationSetting}
        />,
      ];
    }

    if (!isCurrentOrFutureWorkshift) {
      return [
        <ChangeGridButton key="change-grid-button" />,
        OrderNumberButton,
        <OpenSettingsButton key="open-settings-button" />,
      ];
    }

    if (!isCurrentWorkshift && isCurrentOrFutureWorkshift) {
      return [
        <CreateOrderButton key="create-order-button" />,
        <ChangeGridButton key="change-grid-button" />,
        OrderNumberButton,
        <OpenSettingsButton key="open-settings-button" />,
      ];
    }
    return [
      <CreateOrderButton key="create-order-button" />,
      <ChangeGridButton key="change-grid-button" />,
      OrderNumberButton,
      <OpenSettingsButton key="open-settings-button" />,
      <HereOptButton
        key="here-sdk-optimize-button"
        disabled={!isManualOptimizationSetting}
      />,
      <CityFlowButton
        key="city-flow-optimize-button"
        disabled={!isManualOptimizationSetting}
      />,
    ];
  }, [
    isManualOptimizationSetting,
    isCurrentWorkshift,
    isCurrentOrFutureWorkshift,
    onlyCurrentWorkshift,
  ]);

  const filteredButtons = useMemo(() => {
    return isCurrentResourceHasWorkshift
      ? buttons
      : [
          <ChooseWorkshiftButton key="choose-workshift-button" />,
          <OpenSettingsButton key="open-settings-button" />,
        ];
  }, [isCurrentResourceHasWorkshift, buttons]);

  return (
    <Section
      label={t`Orders`}
      emptyText={t`No orders loaded`}
      buttons={filteredButtons}
      sx={sectionStyle}
    >
      {content}
    </Section>
  );
};
