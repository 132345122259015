import React, { useMemo } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { t } from 'ttag';
import { ShipmentStatuses } from 'types/api';

import { styles } from './styles';

export const enum OrderItemType {
  TerminalPickUp = 'TerminalPickUp',
  CourierPickUp = 'CourierPickUp',
  CourierDelivery = 'CourierDelivery',
  TerminalDelivery = 'TerminalDelivery',
  TerminalReturn = 'TerminalReturn',
  Unassigned = 'Unassigned',
}

export const enum AvailableStatusesListItemType {
  PickupForScannedTasks = 'PickupForScannedTasks',
  CopyOrder = 'CopyOrder',
  ChangeStatus = 'ChangeStatus',
}

export interface AvailableStatusesList {
  title: string;
  itemType: AvailableStatusesListItemType;
  newStatus?: ShipmentStatuses;
}
export type ParticularStatusForChanging = Partial<
  Record<ShipmentStatuses, AvailableStatusesList[]>
>;
export type AvailableStatusesForChanging = {
  [key in OrderItemType]?: ParticularStatusForChanging;
};

export const availableStatusesForChanging: AvailableStatusesForChanging = {
  [OrderItemType.TerminalPickUp]: {
    [ShipmentStatuses.ASSIGNED]: [
      {
        title: t`Set up confirmed`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.CONFIRMED,
      },
    ],
    [ShipmentStatuses.CONFIRMED]: [
      {
        title: t`Set up picked up only scanned`,
        itemType: AvailableStatusesListItemType.PickupForScannedTasks,
      },
      {
        title: t`Set up picked up all`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.PICKED,
      },
    ],
  },

  [OrderItemType.CourierPickUp]: {
    [ShipmentStatuses.ASSIGNED]: [
      {
        title: t`Set up confirmed`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.CONFIRMED,
      },
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
    [ShipmentStatuses.CONFIRMED]: [
      {
        title: t`Set up picked up`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.PICKED,
      },
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
  },

  [OrderItemType.CourierDelivery]: {
    [ShipmentStatuses.ASSIGNED]: [
      {
        title: t`Set up confirmed`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.CONFIRMED,
      },
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
    [ShipmentStatuses.CONFIRMED]: [
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
    [ShipmentStatuses.PICKED]: [
      {
        title: t`Set up delivered`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.DROPPED,
      },
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
  },

  [OrderItemType.TerminalDelivery]: {
    [ShipmentStatuses.ASSIGNED]: [
      {
        title: t`Set up confirmed`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.CONFIRMED,
      },
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
    [ShipmentStatuses.CONFIRMED]: [
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
    [ShipmentStatuses.PICKED]: [
      {
        title: t`Set up delivered`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.DROPPED,
      },
      {
        title: t`Set up failed`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.FAILED_DROP,
      },
      {
        title: t`Copy order to new`,
        itemType: AvailableStatusesListItemType.CopyOrder,
      },
    ],
  },

  [OrderItemType.TerminalReturn]: {
    [ShipmentStatuses.PICKED]: [
      {
        title: t`Set up delivered`,
        itemType: AvailableStatusesListItemType.ChangeStatus,
        newStatus: ShipmentStatuses.DROPPED,
      },
    ],
  },
};

interface CopyOrderToNewPopupProps {
  currentOrderType: OrderItemType;
  currentStatus: ShipmentStatuses;
  onCopyPress: (
    itemType: AvailableStatusesListItemType,
    newStatus?: ShipmentStatuses,
  ) => void;
  isCopyEnabled: boolean;
}

function CopyOrderToNewPopup({
  currentOrderType,
  currentStatus,
  onCopyPress,
  isCopyEnabled = true,
}: CopyOrderToNewPopupProps) {
  const content = useMemo(() => {
    if (!currentOrderType) {
      return null;
    }

    return availableStatusesForChanging?.[currentOrderType]?.[
      currentStatus
    ]?.map((item) => {
      if (
        item.itemType === AvailableStatusesListItemType.CopyOrder &&
        !isCopyEnabled
      ) {
        return null;
      }
      return (
        <Box
          key={item.title}
          sx={styles.itemContainer}
          onClick={() => onCopyPress(item.itemType, item.newStatus)}
        >
          <Typography fontSize="16px">{item.title}</Typography>
        </Box>
      );
    });
  }, [currentOrderType, currentStatus, onCopyPress, isCopyEnabled]);

  if (!currentOrderType) {
    return null;
  }

  return (
    <Box sx={styles.copyContainer} boxShadow={3} component={Grid}>
      {content}
    </Box>
  );
}

export default React.memo(CopyOrderToNewPopup);
