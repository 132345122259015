import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { Box } from '@mui/material';
import { EXTRA_SECTION_TYPE, UNASSIGNED_TASKS } from 'constants/common';
import {
  CHOSEN_DATE,
  ONLY_CURRENT_WORKSHIFT,
  ORDER_ID_URL_PARAM,
  RESOURCE_ID_URL_PARAM,
  WORKSHIFT_ID_URL_PARAM,
} from 'constants/urlParams';
import { format } from 'date-fns';
import { createBrowserHistory } from 'history';
import {
  useAvailableDatesForWorkshiftsQuery,
  useWorkshiftsByResourceIdQuery,
} from 'store/api/apiSlice';
import {
  useChatByResourceIdQuery,
  useUnreadMessagesByResourceQuery,
} from 'store/api/chatApiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { useRemoveSelectedValues } from 'store/hooks/useRemoveSelectedValues';
import {
  clearUnassigned,
  removeSelectedOrderId,
  setAllRoutesSelectedOrder,
  setJustSelectedOrderId,
  setSearchedUnassignedShipmentId,
} from 'store/reducers/orderSlice';
import {
  removeSelectedResourceId,
  setJustSelectedResourceId,
  setSelectedResourceId,
  updateOnlyWithUnread,
} from 'store/reducers/resourceSlice';
import {
  setJustCurrentCommonDate,
  setJustOnlyCurrentWorkshift,
} from 'store/reducers/settingsSlice';
import {
  removeSelectedWorkshiftId,
  setJustWorkshiftId,
  setWorkshiftId,
} from 'store/reducers/workshiftSlice';
import { isAnyUnreadMessageSelector } from 'store/selectors/chat';
import {
  getAllRoutesSelectedOrder,
  getSelectedOrderIdSelector,
} from 'store/selectors/order';
import {
  getOnlyWithUnreadSelector,
  getResourcesListSelector,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';
import {
  getCommonDateSelector,
  getDetailsUnderOrdersSelector,
  getOnlyCurrentWorkshiftSelector,
  getShowDetailSectionSelector,
} from 'store/selectors/settings';
import {
  getCurrentWorkshifIdSelector,
  getSelectedWorkshiftIdSelector,
} from 'store/selectors/workshift';
import { Resource } from 'types/resource';

import { MapOrChatSection } from 'components/screens/HomeScreen/components/MapOrChatSection';
import { OrdersAndDetailsWrapper } from 'components/screens/HomeScreen/components/OrdersAndDetailsWrapper';

import { DetailsSection } from './components/DetailsSection';
import { OrdersSection } from './components/OrdersSection';
import { ResourcesSection } from './components/ResourcesSection';
import { styles } from './styles';

interface HomeScreenProps {
  extraSection: EXTRA_SECTION_TYPE;
}

export const HomeScreen: FC<HomeScreenProps> = ({ extraSection }) => {
  const history = createBrowserHistory();
  const location = useLocation();
  const [currentSearchUrlString, setCurrentSearchUrlString] = useState('');

  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedOrderId = useAppSelector(getSelectedOrderIdSelector);
  const showDetailSection = useAppSelector(getShowDetailSectionSelector);
  const removeSelectedValues = useRemoveSelectedValues({ commonDate: false });
  const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);
  const isAnyUnreadMessages = useAppSelector(isAnyUnreadMessageSelector);
  const onlyWithUnread = useAppSelector(getOnlyWithUnreadSelector);
  const allRoutesSelectedOrder = useAppSelector(getAllRoutesSelectedOrder);
  const detailsUnderOrdersStatus = useAppSelector(
    getDetailsUnderOrdersSelector,
  );
  const onlyCurrentWorkshift = useAppSelector(getOnlyCurrentWorkshiftSelector);
  const resourceList = useAppSelector(getResourcesListSelector);
  const commonDate = useAppSelector(getCommonDateSelector);
  const currentWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: ChatData } = useChatByResourceIdQuery(
    {
      resourceId: selectedResourceId as string,
      workshiftId: currentWorkshiftId as string,
    },
    {
      skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS,
      refetchOnMountOrArgChange: true,
    },
  );
  const { refetch: availableWorkshiftDatesRefetch } =
    useAvailableDatesForWorkshiftsQuery(
      { resource_id: selectedResourceId as string },
      { skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS },
    );

  const preparedCommonDate = useMemo(() => {
    return commonDate ? commonDate : format(new Date(), 'yyyy-MM-dd');
  }, [commonDate]);

  const { refetch } = useUnreadMessagesByResourceQuery(
    {
      date: onlyCurrentWorkshift ? undefined : preparedCommonDate,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const workshiftsQueryParams = useMemo(() => {
    const params = {
      resource_id: selectedResourceId as string,
    };

    if (selectedWorkshiftId) {
      return { ...params, work_shift_id: selectedWorkshiftId as string };
    }

    return {
      ...params,
      search_date: format(Date.now(), 'yyyy-MM-dd'),
    };
  }, [selectedWorkshiftId, selectedResourceId]);

  const { refetch: workshiftsOnDateRefetch } = useWorkshiftsByResourceIdQuery(
    workshiftsQueryParams,
    { skip: !selectedResourceId || selectedResourceId === UNASSIGNED_TASKS },
  );

  const setStateFromQueryParams = useCallback(() => {
    const resourceIdFromUrl = searchParams.get(RESOURCE_ID_URL_PARAM);
    const orderIdFromUrl = searchParams.get(ORDER_ID_URL_PARAM);
    const workshiftIdFromUrl = searchParams.get(WORKSHIFT_ID_URL_PARAM);
    const commonDataFromUrl = searchParams.get(CHOSEN_DATE);
    const isOnlyCurrentWorkshiftFromUrl = searchParams.get(
      ONLY_CURRENT_WORKSHIFT,
    );

    if (!selectedResourceId && resourceIdFromUrl) {
      dispatch(setJustSelectedResourceId(resourceIdFromUrl));
    }

    if (selectedResourceId && !resourceIdFromUrl) {
      dispatch(setJustSelectedResourceId(null));
    }

    if (!selectedOrderId && orderIdFromUrl) {
      dispatch(setJustSelectedOrderId(orderIdFromUrl));
    }

    if (selectedOrderId && !orderIdFromUrl) {
      dispatch(setJustSelectedOrderId(null));
    }

    if (!selectedWorkshiftId && workshiftIdFromUrl) {
      dispatch(setJustWorkshiftId(workshiftIdFromUrl));
    }

    if (selectedWorkshiftId && !workshiftIdFromUrl) {
      dispatch(setJustWorkshiftId(null));
    }

    if (!commonDate && commonDataFromUrl) {
      dispatch(setJustCurrentCommonDate(commonDataFromUrl));
    }

    if (commonDate && !commonDataFromUrl) {
      dispatch(setJustCurrentCommonDate(undefined));
    }

    if (isOnlyCurrentWorkshiftFromUrl) {
      dispatch(
        setJustOnlyCurrentWorkshift(isOnlyCurrentWorkshiftFromUrl === 'true'),
      );
    }
  }, [
    selectedWorkshiftId,
    selectedResourceId,
    selectedOrderId,
    commonDate,
    searchParams,
  ]);

  useEffect(() => {
    if (location.search !== currentSearchUrlString) {
      setStateFromQueryParams();
      setCurrentSearchUrlString(location.search);
    }
  }, [setStateFromQueryParams, location.search, currentSearchUrlString]);

  const setSelectedResourceIdHandler = useCallback(
    (id: string, resource?: Resource) => {
      if (selectedResourceId === UNASSIGNED_TASKS) {
        dispatch(clearUnassigned());
      }

      if (id === UNASSIGNED_TASKS) {
        dispatch(removeSelectedWorkshiftId());
      }

      dispatch(setSearchedUnassignedShipmentId(null));
      dispatch(setSelectedResourceId(id));

      if (!onlyCurrentWorkshift || id !== UNASSIGNED_TASKS) {
        if (resource) {
          // @ts-ignore
          dispatch(setWorkshiftId(resource.work_shifts.at(-1).id));
        }
      }

      dispatch(removeSelectedOrderId());
      if (onlyCurrentWorkshift) {
        dispatch(removeSelectedWorkshiftId());
      }
      dispatch(setAllRoutesSelectedOrder(null));
    },
    [selectedResourceId, onlyCurrentWorkshift, resourceList],
  );

  useEffect(() => {
    if (!isAnyUnreadMessages && onlyWithUnread) {
      dispatch(updateOnlyWithUnread(false));
    }
  }, [dispatch, isAnyUnreadMessages, onlyWithUnread]);

  useEffect(() => {
    return history.listen(({ action, location }) => {
      if (action !== 'POP') {
        return;
      }

      if (selectedOrderId && !location.search?.includes('orderId')) {
        dispatch(removeSelectedOrderId());
      }

      if (
        selectedResourceId &&
        !selectedOrderId &&
        location.search?.includes('resourceId')
      ) {
        dispatch(removeSelectedResourceId());
      }
    });
  }, [history, selectedOrderId, selectedResourceId]);

  useEffect(() => {
    refetch();
    availableWorkshiftDatesRefetch();
    workshiftsOnDateRefetch();
  }, []);

  // const extraSectionComponent =
  //   extraSection === EXTRA_SECTION_TYPE.map ? <MapSection /> : <ChatSection />;

  return (
    <Box sx={styles.container}>
      <ResourcesSection
        selectedResourceId={selectedResourceId}
        setSelectedResourceId={setSelectedResourceIdHandler}
        selectAllResources={removeSelectedValues}
      />
      <OrdersAndDetailsWrapper isWrap={detailsUnderOrdersStatus}>
        {selectedResourceId && (
          <OrdersSection
            halfSize={detailsUnderOrdersStatus}
            selectedOrderId={selectedOrderId}
            selectedResourceId={selectedResourceId}
          />
        )}
        {(selectedOrderId !== null || allRoutesSelectedOrder !== null) &&
          showDetailSection && (
            <DetailsSection
              halfSize={detailsUnderOrdersStatus}
              allRoutesSelectedOrder={allRoutesSelectedOrder}
            />
          )}
      </OrdersAndDetailsWrapper>
      <MapOrChatSection type={extraSection} />
      {/* {extraSectionComponent} */}
    </Box>
  );
};
