import { memo } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Stack, SxProps, Typography } from '@mui/material';
import { format } from 'date-fns';

import { styles } from './styles';

export interface UpdatedInfoProps {
  date: string;
  driver: string;
  customStyle?: SxProps;
}

export const UpdatedInfo = memo((props: UpdatedInfoProps) => {
  const { date, driver, customStyle = {} } = props;

  if (!date || !driver) {
    return null;
  }

  return (
    <Stack direction="row" alignItems="center" spacing={1} sx={customStyle}>
      <InfoOutlinedIcon sx={styles.icon} />
      <Typography sx={styles.title}>
        was edited {format(new Date(date), 'dd/mm/yyyy')} by {driver}
      </Typography>
    </Stack>
  );
});
