import { t } from 'ttag';

export const validationMessage = {
  licenceMaxLength: t`Max length is 30 symbols`,
  licenceMinLength: t`Min length is 3 symbols`,
  licenceRequire: t`License plate is required`,
  odometerMin: t`Min odometer value is 1`,
  odometerRequire: t`Odometer is required`,
  amountMin: t`Min amount is 1`,
  amountMax: t`Max amount is 1000`,
  amountRequire: t`Amount is required`,
};
