export const styles = {
  icon: {
    color: 'custom.grey',
    width: '16px',
  },
  title: {
    color: 'custom.grey',
    fontSize: '14px',
    fontWeight: '600',
    lineHeight: '16px',
  },
};
