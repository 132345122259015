import { t } from 'ttag';

export type FuelReportHeadCellTitle =
  | 'resource_number'
  | 'licence_plate'
  | 'driver'
  | 'created_at'
  | 'current_odometer'
  | 'fuel_type';

export type HeadCell = {
  id: FuelReportHeadCellTitle;
  numeric: boolean;
  disablePadding: boolean;
  label: string;
  isSorted: boolean;
};

export const fuelReportHeadCells: readonly HeadCell[] = [
  {
    id: 'resource_number',
    numeric: false,
    disablePadding: true,
    label: t`RESOURCE`,
    isSorted: true,
  },
  {
    id: 'licence_plate',
    numeric: false,
    disablePadding: false,
    label: t`LICENCE PLATE`,
    isSorted: true,
  },
  {
    id: 'driver',
    numeric: false,
    disablePadding: false,
    label: t`DRIVER`,
    isSorted: true,
  },
  {
    id: 'created_at',
    numeric: false,
    disablePadding: false,
    label: t`DATE & TIME`,
    isSorted: true,
  },
  {
    id: 'current_odometer',
    numeric: false,
    disablePadding: true,
    label: t`ODOMETER`,
    isSorted: true,
  },
  {
    id: 'fuel_type',
    numeric: false,
    disablePadding: true,
    label: t`FUEL TYPE`,
    isSorted: true,
  },
];

export const strWithoutMinus = (str: string) => {
  if (str.startsWith('-')) {
    return str.slice(1);
  }

  return str;
};

export const arrToStr = (arr: number[]) => {
  if (!arr || !arr?.length) return '';

  return arr.join(' ');
};
