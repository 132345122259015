import React, { FC, useEffect } from 'react';
import { MAP_COMPONENT_TYPE } from 'constants/common';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import { updateIsMapSectionActive } from 'store/reducers/settingsSlice';
import {
  getResourcesWithCoordinates,
  getSelectedResourceIdSelector,
} from 'store/selectors/resource';

import { Map, MapForAllResources } from 'components/common/Map';

export const MapSection: FC = () => {
  const resourcesWithCoordinates = useAppSelector(getResourcesWithCoordinates);
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(updateIsMapSectionActive(true));

    return () => {
      dispatch(updateIsMapSectionActive(false));
    };
  }, []);

  return selectedResourceId ? (
    <Map
      resources={resourcesWithCoordinates}
      type={MAP_COMPONENT_TYPE.shipments}
    />
  ) : (
    <MapForAllResources
      // resources={resourcesWithCoordinates}
      type={MAP_COMPONENT_TYPE.resources}
    />
  );
};
