import React, {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import {
  Box,
  Grid,
  ListItem,
  Popover,
  SxProps,
  Typography,
} from '@mui/material';
import temperedGoodsImg from 'assets/images/tempered_goods.png';
import {
  APP_ROUTES,
  CHANGE_PAGE_ROUTES,
  RESPONSE_FOR_TERMINAL_WITHOUT_ERRANDS,
  UNASSIGNED_TASKS,
} from 'constants/common';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import {
  useSetPickedupStatusForTerminalTasksMutation,
  useSetShipmentStatusManuallyMutation,
} from 'store/api/apiSlice';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  setSelectedOrderId,
  setUnassignedPageNumberByShipmentId,
} from 'store/reducers/orderSlice';
import {
  setToast,
  updateShowDetailSection,
} from 'store/reducers/settingsSlice';
import {
  calculateUnreadMessagesForShipment,
  isAnyUnreadInGroup,
} from 'store/selectors/chat';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getShowDetailSectionSelector } from 'store/selectors/settings';
import {
  getCurrentWorkshifIdSelector,
  getIsCurrentOrFutureWorkshiftSelector,
} from 'store/selectors/workshift';
import { t } from 'ttag';
import {
  ErrandDataProofTypes,
  ErrandPriorityType,
  ShipmentStatuses,
} from 'types/api';
import { CopyOrderPopupCoords } from 'types/common';
import {
  Order,
  OrderAddressTypes,
  OrderStatuses,
  OrderTypes,
} from 'types/orders';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { Time } from 'components/common/Time';
import { UnreadLabel } from 'components/common/UnreadLabel';
import { CopyOrderToNewPopup } from 'components/ui/CopyOrderToNewPopup';
import {
  availableStatusesForChanging,
  AvailableStatusesListItemType,
  OrderItemType,
} from 'components/ui/CopyOrderToNewPopup/CopyOrderToNewPopup';
import { DialogModal } from 'components/ui/DialogModal';
import { EditButton } from 'components/ui/EditButton';
import { MoveToCurrentWorkshiftButton } from 'components/ui/MoveToCurrentWorkshiftButton';

import { OrdersSectionCollapsibleList } from '../OrdersSectionCollapsibleList';
import { OrdersSectionListItemIcon } from '../OrdersSectionListItemIcon';

import { styles } from './styles';

interface OrdersSectionListItemProps extends Order {
  selectedOrderId?: string | null;
  number: number | null;
}

const OrdersSectionListItem: FC<OrdersSectionListItemProps> = ({
  selectedOrderId,
  number,
  ...order
}) => {
  const [isSubListOpen, setIsSubListOpen] = useState(false);
  const [contextItemType, setContextItemType] =
    useState<AvailableStatusesListItemType | null>(null);
  const [newShipmentStatus, setNewShipmentStatus] =
    useState<ShipmentStatuses | null>(null);
  const [openModal, setOpenModal] = useState(false);
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedWorkshiftId = useAppSelector(getCurrentWorkshifIdSelector);
  const showDetailSection = useAppSelector(getShowDetailSectionSelector);
  const dispatch = useDispatch();
  const unreadMessages = useAppSelector(
    calculateUnreadMessagesForShipment(order?.shipmentId),
  );
  const isCurrentOrFutureWorkshift = useAppSelector(
    getIsCurrentOrFutureWorkshiftSelector,
  );
  const itemRef = useRef<null | HTMLElement>(null);
  const [isItemClick, setIsItemClick] = useState(false);
  const [popupCoords, setPopupCoords] = useState<CopyOrderPopupCoords | null>(
    null,
  );
  const [setPickedUpStatus] = useSetPickedupStatusForTerminalTasksMutation();
  const [setManualStatus] = useSetShipmentStatusManuallyMutation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const isCollapsible = order.isCollapsible;
  const selected = selectedOrderId === order.id && !isCollapsible;
  const isGrouped = useMemo(
    () =>
      order.id.includes('group-') &&
      selectedOrderId === order.id &&
      isCollapsible,
    [order.id, isCollapsible, selectedOrderId],
  );

  const isSubListShown = isCollapsible && isSubListOpen;
  const isDropOptions = !!order?.dropOptions?.length;

  const isTerminalPickup = order?.id?.startsWith('terminal');
  const isTerminalReturn = order.isTerminalReturn;
  const isCourierPick =
    order.type === OrderTypes.PICK &&
    order.address.type === OrderAddressTypes.COURIER;
  const isCourierDrop =
    order.type === OrderTypes.DROP &&
    order.address.type === OrderAddressTypes.COURIER;
  const isTerminalDelivery =
    order.type === OrderTypes.DROP &&
    order.address.type === OrderAddressTypes.TERMINAL &&
    !order.isTerminalReturn;
  // const isTerminalPickupPending =
  //   order?.id?.startsWith('terminal') &&
  //   order?.shipmentStatus === ShipmentStatuses.CONFIRMED;

  const isGroupedHasTempered = useMemo(() => {
    if (!isDropOptions) {
      return false;
    }

    return (
      isCollapsible &&
      !!order?.dropOptions?.some((o) => o.bookingData?.tempered_transport)
    );
  }, [isDropOptions, isCollapsible]);

  const groupedPriority = useMemo(() => {
    if (!isDropOptions) {
      return null;
    }

    if (!isCollapsible) {
      return null;
    }

    if (order?.isTerminalReturn) {
      return null;
    }

    const priorities = order?.dropOptions
      ?.map((p) => (p?.bookingData?.priority ? p?.bookingData?.priority : null))
      ?.filter(Boolean);

    if (priorities?.length) {
      const groupedHasPriority = String(Math.min(...priorities.map(Number)));

      return groupedHasPriority;
    }

    return null;
  }, [isDropOptions, isCollapsible, order]);

  const isTempered =
    (!isDropOptions && order?.bookingData?.tempered_transport) ||
    isGroupedHasTempered;

  const priority = useMemo(() => {
    if (!isDropOptions) {
      return order?.bookingData?.priority;
    }

    return groupedPriority;
  }, [isDropOptions, groupedPriority]);

  const groupedShipmentsIds: string[] = useMemo(() => {
    if (!isDropOptions) {
      return [];
    }

    return order?.dropOptions?.map((option) => option.shipmentId) || [];
  }, [isDropOptions, order]);

  const isSelectedOrderIdInOptions = useMemo(() => {
    if (!isDropOptions) {
      return false;
    }

    return !!order?.dropOptions?.some(
      (option) => option.id === selectedOrderId,
    );
  }, [isDropOptions, order, selectedOrderId]);

  useEffect(() => {
    if (
      (selectedOrderId === order.id || isSelectedOrderIdInOptions) &&
      itemRef?.current
    ) {
      if (!isItemClick) {
        setTimeout(() => {
          itemRef?.current?.scrollIntoView?.({
            behavior: 'smooth',
            block: 'start',
          });
        }, 300);
      } else {
        setIsItemClick(false);
      }
    }
  }, [selected]);

  useEffect(() => {
    if (isSelectedOrderIdInOptions || isGrouped) {
      setIsSubListOpen(true);
    }
  }, [isSelectedOrderIdInOptions, isGrouped]);

  const isAnyUnreadMessages = useAppSelector(
    isAnyUnreadInGroup(groupedShipmentsIds),
  );

  const setIsSubListOpenHandler = () => {
    setIsSubListOpen((prevValue) => !prevValue);
  };

  const setSelectedOrderIdHandler = (orderId: string | undefined) => {
    dispatch(setSelectedOrderId(orderId));
  };

  const clickHandler = useCallback(() => {
    if (!showDetailSection) {
      dispatch(updateShowDetailSection(true));
    }
    setIsItemClick(true);

    if (isSubListShown) {
      setSelectedOrderIdHandler(undefined);
    } else {
      setSelectedOrderIdHandler(order.id);
      if (selectedResourceId === UNASSIGNED_TASKS) {
        dispatch(setUnassignedPageNumberByShipmentId(order.shipmentId));
      }
    }

    setIsSubListOpenHandler();
  }, [isSubListShown, order, selectedOrderId, showDetailSection, dispatch]);

  const handleClose = useCallback(() => {
    setPopupCoords(null);
  }, []);

  const handleCopyPress = useCallback(() => {
    navigateWithQueryParams(
      `${APP_ROUTES.create}/${CHANGE_PAGE_ROUTES.orderDetails}`,
      { orderId: order.id },
    );
    handleClose();
  }, [navigateWithQueryParams, order.id, handleClose]);

  // const handleSetupPickedupPress = useCallback(() => {
  //   handleClose();
  //   setOpenModal(true);
  // }, []);

  const handleContextItemPress = useCallback(
    (itemType: AvailableStatusesListItemType, newStatus?: ShipmentStatuses) => {
      setContextItemType(itemType);
      setNewShipmentStatus(newStatus || null);
      handleClose();
      setOpenModal(true);
    },
    [],
  );

  const isCopyEnabled = useMemo(() => {
    return !isDropOptions;
  }, [isDropOptions]);

  const currentOrderType = useMemo(() => {
    if (isTerminalPickup) {
      return OrderItemType.TerminalPickUp;
    }
    if (isTerminalDelivery) {
      return OrderItemType.TerminalDelivery;
    }
    if (isTerminalReturn) {
      return OrderItemType.TerminalReturn;
    }
    if (isCourierPick) {
      return OrderItemType.CourierPickUp;
    }

    if (isCourierDrop) {
      return OrderItemType.CourierDelivery;
    }
    return OrderItemType.TerminalDelivery;
  }, [
    isTerminalPickup,
    isTerminalReturn,
    isTerminalDelivery,
    isCourierPick,
    isCourierDrop,
  ]);

  const contextHandler = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      if (
        !isCurrentOrFutureWorkshift ||
        !availableStatusesForChanging?.[currentOrderType]?.[
          order.shipmentStatus
        ] ||
        (availableStatusesForChanging?.[currentOrderType]?.[
          order.shipmentStatus
        ]?.[0]?.itemType === AvailableStatusesListItemType.CopyOrder &&
          !isCopyEnabled)
      ) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();

      // if (!isDropOptions || isTerminalPickupPending) {
      setPopupCoords({ top: e.pageY, left: e.pageX });
      // }
    },
    [isCurrentOrFutureWorkshift, order, currentOrderType, isCopyEnabled],
  );

  const setAllScanedAsPickedUp = useCallback(async () => {
    if (!isDropOptions || !selectedResourceId || !selectedWorkshiftId) {
      return;
    }

    const ids = order.dropOptions?.map(({ shipmentId }) => shipmentId) || [];

    const response = await setPickedUpStatus({
      ids,
      resourceId: selectedResourceId,
      workshiftId: selectedWorkshiftId,
    }).unwrap();

    if (response === RESPONSE_FOR_TERMINAL_WITHOUT_ERRANDS) {
      dispatch(
        setToast({
          message: t`No shipments were found to be set as picked up`,
          severity: 'error',
        }),
      );
    }
  }, [order, selectedResourceId, selectedWorkshiftId, isDropOptions]);

  const handleManualStatusPress = useCallback(async () => {
    if (!selectedResourceId || !selectedWorkshiftId || !newShipmentStatus) {
      return;
    }

    let ids = order.dropOptions?.map(({ shipmentId }) => shipmentId) || [];

    if (!ids.length) {
      ids = [order.shipmentId];
    }

    await setManualStatus({
      ids,
      resourceId: selectedResourceId,
      workshiftId: selectedWorkshiftId,
      status: newShipmentStatus,
    });
  }, [order, selectedResourceId, selectedWorkshiftId, newShipmentStatus]);

  const handleModalConfirm = useCallback(async () => {
    if (!contextItemType) {
      return;
    }

    if (
      contextItemType === AvailableStatusesListItemType.PickupForScannedTasks
    ) {
      await setAllScanedAsPickedUp();
      setContextItemType(null);
      setNewShipmentStatus(null);
      return;
    }

    if (contextItemType === AvailableStatusesListItemType.CopyOrder) {
      handleCopyPress();
      setContextItemType(null);
      setNewShipmentStatus(null);
      return;
    }

    if (contextItemType === AvailableStatusesListItemType.ChangeStatus) {
      await handleManualStatusPress();
      setContextItemType(null);
      setNewShipmentStatus(null);
      return;
    }
  }, [
    isDropOptions,
    selectedResourceId,
    selectedWorkshiftId,
    order,
    handleCopyPress,
    setAllScanedAsPickedUp,
    handleManualStatusPress,
  ]);

  const dialogTitle = useMemo(() => {
    if (
      contextItemType === AvailableStatusesListItemType.PickupForScannedTasks
    ) {
      return t` Are you sure you want to set the "Picked up" status to scanned parcels?`;
    }

    if (contextItemType === AvailableStatusesListItemType.CopyOrder) {
      return t`Are you sure you want to copy chosen order?`;
    }

    if (contextItemType === AvailableStatusesListItemType.ChangeStatus) {
      if (newShipmentStatus === ShipmentStatuses.CONFIRMED) {
        return t`Are you sure you want to set the "Confirmed" status?`;
      }
      if (newShipmentStatus === ShipmentStatuses.PICKED) {
        return t`Are you sure you want to set the "Picked up" status?`;
      }
      if (newShipmentStatus === ShipmentStatuses.DROPPED) {
        return t`Are you sure you want to set the "Delivered" status?`;
      }
      if (newShipmentStatus === ShipmentStatuses.FAILED_DROP) {
        return t`Are you sure you want to set the "Failed delivery" status?`;
      }
    }

    return t`Are you sure?`;
  }, [newShipmentStatus, contextItemType]);

  const iBoxenTitle = useMemo(
    () =>
      order.type === OrderTypes.PICK ? t`iBoxen pickup` : t`iBoxen delivery`,
    [order],
  );

  const itemStyles = selected
    ? ({ ...styles.item, ...styles.selected } as SxProps)
    : ({ ...styles.item, ...(order.isNew ? styles.assigned : {}) } as SxProps);

  const hintColor =
    order.status === OrderStatuses.FAILED ? 'custom.red' : 'custom.green';

  const orderHint = order.hint && (
    <Typography variant="subtitle1" sx={{ color: hintColor }}>
      {order.hint}
    </Typography>
  );

  if (order.isHidden) {
    return null;
  }

  const open = Boolean(popupCoords);
  const id = open ? 'copy-to-new-order-popup' : undefined;

  return (
    <>
      <DialogModal
        open={openModal}
        onConfirm={handleModalConfirm}
        onClose={() => setOpenModal(false)}
        title={dialogTitle}
        content=""
      />
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: styles.popup }}
        anchorReference="anchorPosition"
        anchorPosition={popupCoords as CopyOrderPopupCoords}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CopyOrderToNewPopup
          onCopyPress={handleContextItemPress}
          currentStatus={order.shipmentStatus}
          currentOrderType={currentOrderType}
          isCopyEnabled={isCopyEnabled}
        />
      </Popover>
      <ListItem
        sx={itemStyles}
        key={order.id}
        onClick={clickHandler}
        onContextMenu={contextHandler}
        ref={itemRef as any}
      >
        <OrdersSectionListItemIcon
          status={order.status}
          label={number}
          isGrouped={isCollapsible && !isSubListOpen}
          type={order.type}
          isNew={order.isNew}
          isCurrent={order?.isCurrent}
          priority={priority as ErrandPriorityType | null}
        />

        <Grid container columns={15} sx={styles.content}>
          <Grid item mobile={8} alignSelf="center">
            <Typography variant="subtitle1">
              {order?.data?.proofType === ErrandDataProofTypes.smartLockIboxen
                ? iBoxenTitle
                : order.dataTitle}
            </Typography>
            <Typography sx={{ overflowWrap: 'break-word' }}>
              {order.subtitle}
            </Typography>
            <Typography variant="h3">{order.address.title}</Typography>
            <Typography sx={styles.addressSubtitle}>
              {order.address.subtitle}
            </Typography>
            {orderHint}
          </Grid>

          <Grid item mobile={1} alignSelf="center" pl={1}>
            {isTempered && <img src={temperedGoodsImg} alt="Tempered goods" />}
          </Grid>

          <Grid item mobile={3} alignSelf="center">
            <Time
              time={order?.time}
              isOnlyPod={!!order?.data?.isManualCreated}
            />
          </Grid>

          <Grid item mobile={3} alignSelf="center" textAlign="center" pl={1}>
            {(!!unreadMessages || isAnyUnreadMessages) && (
              <QueryParamsLink sx={{ zIndex: 100 }} onClick={clickHandler}>
                <UnreadLabel qty={isAnyUnreadMessages ? 0 : unreadMessages} />
              </QueryParamsLink>
            )}
          </Grid>
          {order.isEditable && isCurrentOrFutureWorkshift && (
            <Box sx={styles.editButton}>
              <EditButton orderId={order.id} shipmentId={order.shipmentId} />
            </Box>
          )}
          {order.isEditable && !isCurrentOrFutureWorkshift && (
            <Box sx={styles.moveButton}>
              <MoveToCurrentWorkshiftButton
                workshiftId={selectedWorkshiftId as string}
                shipmentId={order.shipmentId}
                resourceId={selectedWorkshiftId as string}
              />
            </Box>
          )}
        </Grid>
      </ListItem>
      {isSubListShown && (
        <OrdersSectionCollapsibleList
          setSelectedOrderId={setSelectedOrderIdHandler}
          ordersList={order.dropOptions || []}
          selectedOrderId={selectedOrderId}
        />
      )}
    </>
  );
};

export default React.memo(OrdersSectionListItem);
