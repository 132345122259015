import React, { memo, useCallback, useEffect, useState } from 'react';
import GpsFixedIcon from '@mui/icons-material/GpsFixed';
import {
  Box,
  Button,
  FormControl,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import thirdType from 'assets/images/chasis_2_2_2.png';
import secondType from 'assets/images/chasis_2_4.png';
import fourthType from 'assets/images/chasis_2_4_2.png';
import firstType from 'assets/images/chassis_2_2.png';
import { format } from 'date-fns';
import { Field, Form, FormikHelpers, FormikProvider, useFormik } from 'formik';
import { useCustomBackHandler } from 'hooks/useCustomBackHandker';
import {
  useEditTireReportByIdMutation,
  useTireReportByIdQuery,
} from 'store/api/adminApiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import {
  TireType,
  VehicleType,
  WheelCharacteristic,
  WheelPosition,
} from 'types/tiresReport';

import { ChangeScreenSection } from 'components/common/ChangeScreenSection';
import { FieldInput } from 'components/common/FieldInput';
import { FieldSelect } from 'components/common/FieldSelect';
import {
  mainFormValidationSchema,
  MainFormValues,
  VehicleTypeForm,
} from 'components/screens/AdminHomeScreen/components/CompanyReports/components/TireReportDetail/tireReportDetailValidation';
import { isWheelNotEmpty } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/TireReportDetail/utils';
import { TireReportDetailHeader } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/TireReportDetailHeader';
import { UpdatedInfo } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/UpdatedInfo';

import { styles } from './styles';

interface FeedbackDetailProps {
  selectedId: number;
  setSelectedId: React.Dispatch<React.SetStateAction<number | null>>;
}

const vehicleTypeOptions = [
  { value: '1', label: '2+2', img_name: firstType },
  { value: '2', label: '2+4', img_name: secondType },
  { value: '3', label: '2+2+2', img_name: thirdType },
  { value: '4', label: '2+4+2', img_name: fourthType },
];

type SeasonTypeOption = {
  value: TireType;
  label: string;
};
const seasonTypeOptions: SeasonTypeOption[] = [
  { value: 'Summer', label: t`Summer tires` },
  { value: 'Winter (friction)', label: t`Winter tires (friction)` },
  { value: 'Winter (spiked)', label: t`Winter tires (spiked)` },
];

const defaultValues: MainFormValues = {
  driverName: '',
  licensePlate: '',
  vehicleType: '1',
  seasonType: 'Summer',
  backLeft: {
    depth: 0,
    pressure: 0,
  },
  backRight: {
    depth: 0,
    pressure: 0,
  },
  frontLeft: {
    depth: 0,
    pressure: 0,
  },
  frontRight: {
    depth: 0,
    pressure: 0,
  },
  backLeft2ndRow: {
    depth: 0,
    pressure: 0,
  },
  backRight2ndRow: {
    depth: 0,
    pressure: 0,
  },
  backLeftOuter: {
    depth: 0,
    pressure: 0,
  },
  backRightOuter: {
    depth: 0,
    pressure: 0,
  },
};

export const TireReportDetail = memo((props: FeedbackDetailProps) => {
  const { selectedId, setSelectedId } = props;
  const { data } = useTireReportByIdQuery(
    { reportId: selectedId },
    { refetchOnMountOrArgChange: true },
  );
  const [initialValues, setInitialValues] =
    useState<MainFormValues>(defaultValues);
  const [editTireReport] = useEditTireReportByIdMutation();
  const dispatch = useAppDispatch();

  const backHandler = useCallback(() => {
    if (selectedId) {
      setSelectedId(null);
      return false;
    }

    return true;
  }, [selectedId, setSelectedId]);

  useCustomBackHandler(backHandler);

  useEffect(() => {
    if (data) {
      setInitialValues({
        driverName: data?.driver_name || '',
        licensePlate: data?.licence_plate || '',
        vehicleType: (data?.vehicle_type
          ? String(data?.vehicle_type)
          : '1') as VehicleTypeForm,
        seasonType: data?.tyre_season || 'Summer',
        backLeft: {
          depth: data?.data?.['Back left']?.depth || 0,
          pressure: data?.data?.['Back left']?.pressure || 0,
        },
        backRight: {
          depth: data?.data?.['Back right']?.depth || 0,
          pressure: data?.data?.['Back right']?.pressure || 0,
        },
        frontLeft: {
          depth: data?.data?.['Front left']?.depth || 0,
          pressure: data?.data?.['Front left']?.pressure || 0,
        },
        frontRight: {
          depth: data?.data?.['Front right']?.depth || 0,
          pressure: data?.data?.['Front right']?.pressure || 0,
        },
        backLeft2ndRow: {
          depth: data?.data?.['Back left 2nd row']?.depth || 0,
          pressure: data?.data?.['Back left 2nd row']?.pressure || 0,
        },
        backRight2ndRow: {
          depth: data?.data?.['Back right 2nd row']?.depth || 0,
          pressure: data?.data?.['Back right 2nd row']?.pressure || 0,
        },
        backLeftOuter: {
          depth: data?.data?.['Back left outer']?.depth || 0,
          pressure: data?.data?.['Back left outer']?.pressure || 0,
        },
        backRightOuter: {
          depth: data?.data?.['Back right outer']?.depth || 0,
          pressure: data?.data?.['Back right outer']?.pressure || 0,
        },
      });
    }
  }, [data]);

  const onSubmit = useCallback(
    async (
      formValues: MainFormValues,
      { setSubmitting, resetForm }: FormikHelpers<MainFormValues>,
    ) => {
      setSubmitting(true);
      const additionalWheels: Partial<
        Record<WheelPosition, WheelCharacteristic>
      > = {};

      const isBackOuterEnable =
        formValues.vehicleType === '2' || formValues.vehicleType === '4';
      const isBack2ndRowEnable =
        formValues.vehicleType === '3' || formValues.vehicleType === '4';

      if (isWheelNotEmpty(formValues.backLeftOuter) && isBackOuterEnable) {
        additionalWheels['Back left outer'] = formValues.backLeftOuter;
      }
      if (isWheelNotEmpty(formValues.backRightOuter) && isBackOuterEnable) {
        additionalWheels['Back right outer'] = formValues.backLeftOuter;
      }
      if (isWheelNotEmpty(formValues.backLeft2ndRow) && isBack2ndRowEnable) {
        additionalWheels['Back left 2nd row'] = formValues.backLeft2ndRow;
      }
      if (isWheelNotEmpty(formValues.backRight2ndRow) && isBack2ndRowEnable) {
        additionalWheels['Back right 2nd row'] = formValues.backRight2ndRow;
      }

      await editTireReport({
        report_id: selectedId,
        driver_name: formValues.driverName,
        licence_plate: formValues.licensePlate,
        vehicle_type: Number(formValues.vehicleType) as VehicleType,
        tyre_season: formValues.seasonType as TireType,
        data: {
          'Back left': formValues.backLeft,
          'Back right': formValues.backRight,
          'Front left': formValues.frontLeft,
          'Front right': formValues.frontRight,
          ...additionalWheels,
        },
      });

      setSubmitting(false);
      resetForm();
      setSelectedId(null);
      dispatch(
        setToast({
          message: t`You have successfully updated tire report`,
          severity: 'success',
        }),
      );
    },
    [setSelectedId, selectedId],
  );

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: mainFormValidationSchema,
    onSubmit,
  });

  const handleCancelPress = useCallback(() => {
    setSelectedId(null);
  }, [setSelectedId]);

  const handleLocationPress = useCallback(() => {
    const url = `https://www.google.com/maps?q=${data?.gps_location?.latitude},${data?.gps_location?.longitude}`;
    window.open(url, '_blank');
  }, [data]);

  return (
    <FormikProvider value={formik}>
      <FormControl
        component={Form}
        sx={styles.container}
        noValidate
        autoComplete="off"
      >
        <TireReportDetailHeader
          licence={data?.licence_plate || ''}
          resourceNumber={data?.resource_number || ''}
          setCurrentFeedbackId={setSelectedId}
        />

        <Stack direction="column" sx={styles.contentBlock}>
          <UpdatedInfo
            date={data?.updated_at || ''}
            driver={data?.updated || ''}
            customStyle={styles.updateInfo}
          />
          <Grid
            container
            columns={6}
            spacing={2}
            rowGap={1}
            padding={2}
            mt="8px"
          >
            <Grid item mobile={3}>
              <TextField
                id="Time"
                label={t`Time`}
                value={
                  data?.created_at
                    ? format(new Date(data.created_at), 'hh:mm aa')
                    : ''
                }
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>
            <Grid item mobile={3}>
              <TextField
                id="Date"
                label={t`Date`}
                value={
                  data?.created_at
                    ? format(new Date(data.created_at), 'dd.MM.yyyy')
                    : ''
                }
                disabled
                fullWidth
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>

            <Grid item mobile={3}>
              <TextField
                id="resurceNumber"
                label={t`Resource number`}
                placeholder={t`Resource`}
                value={data?.resource_number || ''}
                fullWidth
                disabled
                InputLabelProps={{ shrink: true }}
                sx={styles.input}
              />
            </Grid>

            <Grid item mobile={3}>
              <Field
                component={FieldInput}
                name="driverName"
                label={t`Driver name`}
                // placeholder={t`Enter freight bill`}
                fullWidth
                // isTrim
                autoComplete="off"
                disabled
              />
            </Grid>

            <Grid item mobile={3}>
              <Box sx={styles.gpsBlock}>
                <TextField
                  id="gpsLocation"
                  label={t`GPS - location`}
                  value={`${data?.gps_location?.latitude} - ${data?.gps_location?.latitude}`}
                  fullWidth
                  disabled
                  InputLabelProps={{ shrink: true }}
                  sx={styles.input}
                />
                <IconButton sx={styles.gpsIcon} onClick={handleLocationPress}>
                  <GpsFixedIcon color="primary" />
                </IconButton>
              </Box>
            </Grid>
            <Grid item mobile={3}>
              <Field
                component={FieldInput}
                name="licensePlate"
                label={t`Licence plate`}
                // placeholder={t`Enter freight bill`}
                fullWidth
                isTrim
                autoComplete="off"
                disabled
              />
            </Grid>
            <Grid item mobile={3}>
              <Field
                component={FieldSelect}
                name="vehicleType"
                label={t`Vehicle type`}
                // placeholder={t`Enter freight bill`}
                fullWidth
                options={vehicleTypeOptions}
                autoComplete="off"
                disabled
              />
            </Grid>
            <Grid item mobile={3}>
              <Field
                component={FieldSelect}
                name="seasonType"
                label={t`Tire type`}
                // placeholder={t`Enter freight bill`}
                fullWidth
                options={seasonTypeOptions}
                autoComplete="off"
                sx={{ height: '54px' }}
              />
            </Grid>
            <Grid item mobile={3}>
              <ChangeScreenSection label={t`Front left tire`}>
                <Grid container columns={1} rowGap={2}>
                  <Grid container columns={1} spacing={2} rowGap={4}>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="frontLeft.pressure"
                        label={t`Pressure`}
                        // placeholder={t`Enter waiting time delivery`}
                        type="number"
                      />
                      <Typography>{t`bar`}</Typography>
                    </Grid>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="frontLeft.depth"
                        label={t`Tread depth`}
                        // placeholder={t`Enter unloading time`}
                        type="number"
                      />
                      <Typography>{t`mm`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ChangeScreenSection>
            </Grid>
            <Grid item mobile={3}>
              <ChangeScreenSection label={t`Front right tire`}>
                <Grid container columns={1} rowGap={2}>
                  <Grid container columns={1} spacing={2} rowGap={4}>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="frontRight.pressure"
                        label={t`Pressure`}
                        // placeholder={t`Enter waiting time delivery`}
                        type="number"
                      />
                      <Typography>{t`bar`}</Typography>
                    </Grid>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="frontRight.depth"
                        label={t`Tread depth`}
                        // placeholder={t`Enter unloading time`}
                        type="number"
                      />
                      <Typography>{t`mm`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ChangeScreenSection>
            </Grid>
            <Grid item mobile={3}>
              <ChangeScreenSection label={t`Back left tire`}>
                <Grid container columns={1} rowGap={2}>
                  <Grid container columns={1} spacing={2} rowGap={4}>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="backLeft.pressure"
                        label={t`Pressure`}
                        // placeholder={t`Enter waiting time delivery`}
                        type="number"
                      />
                      <Typography>{t`bar`}</Typography>
                    </Grid>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="backLeft.depth"
                        label={t`Tread depth`}
                        // placeholder={t`Enter unloading time`}
                        type="number"
                      />
                      <Typography>{t`mm`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ChangeScreenSection>
            </Grid>
            <Grid item mobile={3}>
              <ChangeScreenSection label={t`Back right tire`}>
                <Grid container columns={1} rowGap={2}>
                  <Grid container columns={1} spacing={2} rowGap={4}>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="backRight.pressure"
                        label={t`Pressure`}
                        // placeholder={t`Enter waiting time delivery`}
                        type="number"
                      />
                      <Typography>{t`bar`}</Typography>
                    </Grid>
                    <Grid item mobile={1} sx={styles.gridItem}>
                      <Field
                        component={FieldInput}
                        name="backRight.depth"
                        label={t`Tread depth`}
                        // placeholder={t`Enter unloading time`}
                        type="number"
                      />
                      <Typography>{t`mm`}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </ChangeScreenSection>
            </Grid>
            {(formik.values.vehicleType === '2' ||
              formik.values.vehicleType === '4') && (
              <>
                <Grid item mobile={3}>
                  <ChangeScreenSection label={t`Back left outer tire`}>
                    <Grid container columns={1} rowGap={2}>
                      <Grid container columns={1} spacing={2} rowGap={4}>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backLeftOuter.pressure"
                            label={t`Pressure`}
                            // placeholder={t`Enter waiting time delivery`}
                            type="number"
                          />
                          <Typography>{t`bar`}</Typography>
                        </Grid>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backLeftOuter.depth"
                            label={t`Tread depth`}
                            // placeholder={t`Enter unloading time`}
                            type="number"
                          />
                          <Typography>{t`mm`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ChangeScreenSection>
                </Grid>
                <Grid item mobile={3}>
                  <ChangeScreenSection label={t`Back right outer tire`}>
                    <Grid container columns={1} rowGap={2}>
                      <Grid container columns={1} spacing={2} rowGap={4}>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backRightOuter.pressure"
                            label={t`Pressure`}
                            // placeholder={t`Enter waiting time delivery`}
                            type="number"
                          />
                          <Typography>{t`bar`}</Typography>
                        </Grid>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backRightOuter.depth"
                            label={t`Tread depth`}
                            // placeholder={t`Enter unloading time`}
                            type="number"
                          />
                          <Typography>{t`mm`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ChangeScreenSection>
                </Grid>
              </>
            )}
            {(formik.values.vehicleType === '3' ||
              formik.values.vehicleType === '4') && (
              <>
                <Grid item mobile={3}>
                  <ChangeScreenSection label={t`Back left 2nd row tire`}>
                    <Grid container columns={1} rowGap={2}>
                      <Grid container columns={1} spacing={2} rowGap={4}>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backLeft2ndRow.pressure"
                            label={t`Pressure`}
                            // placeholder={t`Enter waiting time delivery`}
                            type="number"
                          />
                          <Typography>{t`bar`}</Typography>
                        </Grid>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backLeft2ndRow.depth"
                            label={t`Tread depth`}
                            // placeholder={t`Enter unloading time`}
                            type="number"
                          />
                          <Typography>{t`mm`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ChangeScreenSection>
                </Grid>
                <Grid item mobile={3}>
                  <ChangeScreenSection label={t`Back right 2nd row tire`}>
                    <Grid container columns={1} rowGap={2}>
                      <Grid container columns={1} spacing={2} rowGap={4}>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backRight2ndRow.pressure"
                            label={t`Pressure`}
                            // placeholder={t`Enter waiting time delivery`}
                            type="number"
                          />
                          <Typography>{t`bar`}</Typography>
                        </Grid>
                        <Grid item mobile={1} sx={styles.gridItem}>
                          <Field
                            component={FieldInput}
                            name="backRight2ndRow.depth"
                            label={t`Tread depth`}
                            // placeholder={t`Enter unloading time`}
                            type="number"
                          />
                          <Typography>{t`mm`}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </ChangeScreenSection>
                </Grid>
              </>
            )}
          </Grid>

          <Box sx={styles.buttonBlock}>
            <Button
              onClick={handleCancelPress}
              sx={styles.button}
              variant="outlined"
            >
              {t`Cancel`}
            </Button>
            <Button
              // onClick={handleSavePress}
              variant="contained"
              sx={styles.button}
              type="submit"
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {t`Save`}
            </Button>
          </Box>
        </Stack>
      </FormControl>
    </FormikProvider>
  );
});
