import { FuelTypes } from 'types/fuelReport';
import * as Yup from 'yup';

import { validationMessage } from './fuelReportDetailsValidationMessages';

export interface MainFormValues {
  licensePlate: string;
  fuelType: FuelTypes;
  odometer: number;
  amount: number;
}

// @ts-ignore
export const mainFormValidationSchema: Yup.SchemaOf<MainFormValues> =
  Yup.object().shape({
    licensePlate: Yup.string()
      .required(validationMessage.licenceRequire)
      .max(30, validationMessage.licenceMaxLength)
      .min(3, validationMessage.licenceMinLength),
    fuelType: Yup.string().required(),
    odometer: Yup.number()
      .required(validationMessage.odometerRequire)
      .min(1, validationMessage.odometerMin),
    amount: Yup.number()
      .required(validationMessage.amountRequire)
      .min(1, validationMessage.amountMin)
      .max(1000, validationMessage.amountMax),
  });
