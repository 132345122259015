export const styles = {
  imageContainer: {
    position: 'relative',
    width: '85px',
    height: '85px !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',

    '& > img': {
      flexShrink: 0,
      minWidth: '100%',
      minHeight: '100%',
    },

    '&:hover': {
      cursor: 'pointer',
    },
  },

  deleteButton: {
    position: 'absolute',
    right: 0,
    top: 0,
  },
  deleteIcon: {
    color: 'custom.white',
    width: '24px',
  },
  addImageBlock: {
    width: '85px',
    height: '85px',
    borderWidth: '1px',
    borderStyle: 'solid',
    borderColor: 'custom.greyLight',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  addImageIconBlock: {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: 'custom.orangeLight',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};
