import React, {
  Dispatch,
  memo,
  SetStateAction,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import DeleteForeverOutlinedIcon from '@mui/icons-material/DeleteForeverOutlined';
import { IconButton, ImageListItem, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { setToast } from 'store/reducers/settingsSlice';
import { t } from 'ttag';
import { createUniqueId } from 'utils/uniqIdUtils';

import { ImageViewer } from 'components/common/ImageViewer';
import { DialogModal } from 'components/ui/DialogModal';

import { styles } from './styles';

export interface ImagesListProps {
  images: string[];
  setImages: Dispatch<SetStateAction<string[]>>;
  onAddImage: (file: File) => void;
  maxLength: number;
}

export const ImagesList = memo((props: ImagesListProps) => {
  const { images, setImages, onAddImage, maxLength = 6 } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [itemIndex, setItemIndex] = useState(0);
  const fileInputRef = useRef(null);

  const dispatch = useDispatch();

  const handleClick = (currIdx: number) => {
    setIsOpen(true);
    setItemIndex(currIdx);
  };

  const handleClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const handleTrashClick = (currIdx: number) => {
    setItemIndex(currIdx);
    setOpenDeleteModal(true);
  };

  const handleImageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0]; // Get the first file
      if (file && file.type === 'image/jpeg') {
        onAddImage(file);
      } else {
        dispatch(
          setToast({
            message: t`Please select a valid JPG image!`,
            severity: 'error',
          }),
        );
      }
    },
    [onAddImage],
  );

  const handleAddImageClick = useCallback(() => {
    // @ts-ignore
    fileInputRef.current?.click();
  }, []);

  const content = useMemo(() => {
    return images.map((item, idx) => {
      return (
        <React.Fragment key={createUniqueId()}>
          <ImageListItem
            sx={styles.imageContainer}
            onClick={() => handleClick(idx)}
          >
            <IconButton
              sx={styles.deleteButton}
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                handleTrashClick(idx);
              }}
            >
              <DeleteForeverOutlinedIcon sx={styles.deleteIcon} />
            </IconButton>
            <img
              src={item}
              alt="image"
              width={85}
              height={85}
              loading="eager"
            />
          </ImageListItem>
          {idx === images.length - 1 && idx < maxLength - 1 && (
            <Box sx={styles.addImageBlock} onClick={handleAddImageClick}>
              <Box sx={styles.addImageIconBlock}>
                <AddIcon color="primary" fontSize="large" />
                <input
                  ref={fileInputRef}
                  type="file"
                  accept="image/jpeg"
                  hidden
                  onChange={handleImageChange}
                />
              </Box>
            </Box>
          )}
        </React.Fragment>
      );
    });
  }, [images, handleImageChange, handleAddImageClick]);

  const handleDeleteModalConfirm = useCallback(() => {
    setOpenDeleteModal(false);
    const filteredImages = images.filter((image, idx) => idx !== itemIndex);
    setImages(filteredImages);
  }, [itemIndex, images, setImages]);

  return (
    <>
      <Stack direction="row" spacing={2} flexWrap="wrap">
        {content}
      </Stack>
      <ImageViewer
        currentImageIndex={itemIndex}
        open={isOpen}
        onClose={handleClose}
        photoUrls={images}
      />
      <DialogModal
        open={openDeleteModal}
        onConfirm={handleDeleteModalConfirm}
        onClose={() => setOpenDeleteModal(false)}
        title={t`Are you sure you want to delete this image?`}
        content={''}
      />
    </>
  );
});
