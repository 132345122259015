import React, { FC, useCallback, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  ListItem,
  Popover,
  SxProps,
  Typography,
} from '@mui/material';
import temperedGoodsImg from 'assets/images/tempered_goods.png';
import {
  alphabet,
  APP_ROUTES,
  CHANGE_PAGE_ROUTES,
  RESPONSE_FOR_TERMINAL_WITHOUT_ERRANDS,
} from 'constants/common';
import { useNavigateWithQueryParams } from 'hooks/useNavigateWithQueryParams';
import {
  useSetPickedupStatusForTerminalTasksMutation,
  useSetShipmentStatusManuallyMutation,
} from 'store/api/apiSlice';
import { useAppDispatch } from 'store/hooks/useAppDispatch';
import { useAppSelector } from 'store/hooks/useAppSelector';
import {
  setToast,
  updateShowDetailSection,
} from 'store/reducers/settingsSlice';
import { calculateUnreadMessagesForShipment } from 'store/selectors/chat';
import { getSelectedResourceIdSelector } from 'store/selectors/resource';
import { getShowDetailSectionSelector } from 'store/selectors/settings';
import {
  getIsCurrentOrFutureWorkshiftSelector,
  getSelectedWorkshiftIdSelector,
} from 'store/selectors/workshift';
import { t } from 'ttag';
import { ErrandDataProofTypes, ShipmentStatuses } from 'types/api';
import { CopyOrderPopupCoords } from 'types/common';
import { Order, OrderAddressTypes, OrderTypes } from 'types/orders';

import { QueryParamsLink } from 'components/common/QueryParamsLink';
import { Time } from 'components/common/Time';
import { UnreadLabel } from 'components/common/UnreadLabel';
import { CopyOrderToNewPopup } from 'components/ui/CopyOrderToNewPopup';
import {
  availableStatusesForChanging,
  AvailableStatusesListItemType,
  OrderItemType,
} from 'components/ui/CopyOrderToNewPopup/CopyOrderToNewPopup';
import { DialogModal } from 'components/ui/DialogModal';
import { EditButton } from 'components/ui/EditButton';
import { MoveToCurrentWorkshiftButton } from 'components/ui/MoveToCurrentWorkshiftButton';

import { OrdersSectionListItemIcon } from '../OrdersSectionListItemIcon';

import { styles } from './styles';

interface OrdersSectionCollapsibleListItemProps {
  order: Order;
  index: number;
  selected: boolean;
  onClick: (orderId: string) => void;
}

export const OrdersSectionCollapsibleListItem: FC<
  OrdersSectionCollapsibleListItemProps
> = ({ order, selected, onClick, index }) => {
  const dispatch = useAppDispatch();
  const selectedResourceId = useAppSelector(getSelectedResourceIdSelector);
  const selectedWorkshiftId = useAppSelector(getSelectedWorkshiftIdSelector);
  const showDetailSection = useAppSelector(getShowDetailSectionSelector);
  const [popupCoords, setPopupCoords] = useState<CopyOrderPopupCoords | null>(
    null,
  );
  const [contextItemType, setContextItemType] =
    useState<AvailableStatusesListItemType | null>(null);
  const [newShipmentStatus, setNewShipmentStatus] =
    useState<ShipmentStatuses | null>(null);
  const [openModal, setOpenModal] = useState(false);

  const unreadMessages = useAppSelector(
    calculateUnreadMessagesForShipment(order?.shipmentId),
  );
  const isCurrentOrFutureWorkshift = useAppSelector(
    getIsCurrentOrFutureWorkshiftSelector,
  );

  const [setPickedUpStatus] = useSetPickedupStatusForTerminalTasksMutation();
  const [setManualStatus] = useSetShipmentStatusManuallyMutation();
  const { navigateWithQueryParams } = useNavigateWithQueryParams();

  const isTempered = order?.bookingData?.tempered_transport;
  const isDropOptions = !!order?.dropOptions?.length;

  const isTerminalPickup = order?.id?.startsWith('terminal');
  const isTerminalReturn = order.isTerminalReturn;
  const isCourierPick =
    order.type === OrderTypes.PICK &&
    order.address.type === OrderAddressTypes.COURIER;
  const isCourierDrop =
    order.type === OrderTypes.DROP &&
    order.address.type === OrderAddressTypes.COURIER;
  const isTerminalDelivery =
    order.type === OrderTypes.DROP &&
    order.address.type === OrderAddressTypes.TERMINAL &&
    !order.isTerminalReturn;
  const priority = order?.isTerminalReturn
    ? null
    : order?.bookingData?.priority || null;

  const clickHandler = useCallback(() => {
    if (!showDetailSection) {
      dispatch(updateShowDetailSection(true));
    }
    onClick(order.id);
  }, [showDetailSection, dispatch]);

  const handleClose = useCallback(() => {
    setPopupCoords(null);
  }, []);

  const handleCopyPress = useCallback(() => {
    navigateWithQueryParams(
      `${APP_ROUTES.create}/${CHANGE_PAGE_ROUTES.orderDetails}`,
      { orderId: order.id },
    );
    handleClose();
  }, [navigateWithQueryParams, order.id, handleClose]);

  const handleContextItemPress = useCallback(
    (itemType: AvailableStatusesListItemType, newStatus?: ShipmentStatuses) => {
      setContextItemType(itemType);
      setNewShipmentStatus(newStatus || null);
      handleClose();
      setOpenModal(true);
    },
    [],
  );

  const isCopyEnabled = useMemo(() => {
    return !isDropOptions;
  }, [isDropOptions]);

  const currentOrderType = useMemo(() => {
    if (isTerminalPickup) {
      return OrderItemType.TerminalPickUp;
    }
    if (isTerminalDelivery) {
      return OrderItemType.TerminalDelivery;
    }
    if (isTerminalReturn) {
      return OrderItemType.TerminalReturn;
    }
    if (isCourierPick) {
      return OrderItemType.CourierPickUp;
    }

    if (isCourierDrop) {
      return OrderItemType.CourierDelivery;
    }
    return OrderItemType.TerminalDelivery;
  }, [
    isTerminalPickup,
    isTerminalReturn,
    isTerminalDelivery,
    isCourierPick,
    isCourierDrop,
  ]);

  const contextHandler = useCallback(
    (e: React.MouseEvent<Element, MouseEvent>) => {
      if (
        !isCurrentOrFutureWorkshift ||
        !availableStatusesForChanging?.[currentOrderType]?.[
          order.shipmentStatus
        ] ||
        (availableStatusesForChanging?.[currentOrderType]?.[
          order.shipmentStatus
        ]?.[0]?.itemType === AvailableStatusesListItemType.CopyOrder &&
          !isCopyEnabled)
      ) {
        return;
      }

      e.stopPropagation();
      e.preventDefault();

      // if (!isDropOptions || isTerminalPickupPending) {
      setPopupCoords({ top: e.pageY, left: e.pageX });
      // }
    },
    [isCurrentOrFutureWorkshift, order, currentOrderType, isCopyEnabled],
  );

  const setAllScanedAsPickedUp = useCallback(async () => {
    if (!isDropOptions || !selectedResourceId || !selectedWorkshiftId) {
      return;
    }

    const ids = order.dropOptions?.map(({ shipmentId }) => shipmentId) || [];

    const response = await setPickedUpStatus({
      ids,
      resourceId: selectedResourceId,
      workshiftId: selectedWorkshiftId,
    }).unwrap();

    if (response === RESPONSE_FOR_TERMINAL_WITHOUT_ERRANDS) {
      dispatch(
        setToast({
          message: t`No shipments were found to be set as picked up`,
          severity: 'error',
        }),
      );
    }
  }, [order, selectedResourceId, selectedWorkshiftId, isDropOptions]);

  const handleManualStatusPress = useCallback(async () => {
    if (!selectedResourceId || !selectedWorkshiftId || !newShipmentStatus) {
      return;
    }

    let ids = order.dropOptions?.map(({ shipmentId }) => shipmentId) || [];

    if (!ids.length) {
      ids = [order.shipmentId];
    }

    await setManualStatus({
      ids,
      resourceId: selectedResourceId,
      workshiftId: selectedWorkshiftId,
      status: newShipmentStatus,
    });
  }, [order, selectedResourceId, selectedWorkshiftId, newShipmentStatus]);

  const handleModalConfirm = useCallback(async () => {
    if (!contextItemType) {
      return;
    }

    if (
      contextItemType === AvailableStatusesListItemType.PickupForScannedTasks
    ) {
      await setAllScanedAsPickedUp();
      setContextItemType(null);
      setNewShipmentStatus(null);
      return;
    }

    if (contextItemType === AvailableStatusesListItemType.CopyOrder) {
      handleCopyPress();
      setContextItemType(null);
      setNewShipmentStatus(null);
      return;
    }

    if (contextItemType === AvailableStatusesListItemType.ChangeStatus) {
      await handleManualStatusPress();
      setContextItemType(null);
      setNewShipmentStatus(null);
      return;
    }
  }, [
    isDropOptions,
    selectedResourceId,
    selectedWorkshiftId,
    order,
    handleCopyPress,
    setAllScanedAsPickedUp,
    handleManualStatusPress,
  ]);

  const itemStyles = selected
    ? ({ ...styles.item, ...styles.selected } as SxProps)
    : styles.item;

  const open = Boolean(popupCoords);
  const id = open ? 'copy-to-new-order-popup' : undefined;

  const dialogTitle = useMemo(() => {
    if (
      contextItemType === AvailableStatusesListItemType.PickupForScannedTasks
    ) {
      return t` Are you sure you want to set the "Picked up" status to scanned parcels?`;
    }

    if (contextItemType === AvailableStatusesListItemType.CopyOrder) {
      return t`Are you sure you want to copy chosen order?`;
    }

    if (contextItemType === AvailableStatusesListItemType.ChangeStatus) {
      if (newShipmentStatus === ShipmentStatuses.CONFIRMED) {
        return t`Are you sure you want to set the "Confirmed" status?`;
      }
      if (newShipmentStatus === ShipmentStatuses.PICKED) {
        return t`Are you sure you want to set the "Picked up" status?`;
      }
      if (newShipmentStatus === ShipmentStatuses.DROPPED) {
        return t`Are you sure you want to set the "Delivered" status?`;
      }
      if (newShipmentStatus === ShipmentStatuses.FAILED_DROP) {
        return t`Are you sure you want to set the "Failed delivery" status?`;
      }
    }

    return t`Are you sure?`;
  }, [newShipmentStatus, contextItemType]);

  return (
    <>
      <DialogModal
        open={openModal}
        onConfirm={handleModalConfirm}
        onClose={() => setOpenModal(false)}
        title={dialogTitle}
        content=""
      />
      <Popover
        id={id}
        open={open}
        onClose={handleClose}
        PaperProps={{ sx: styles.popup }}
        anchorReference="anchorPosition"
        anchorPosition={popupCoords as CopyOrderPopupCoords}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <CopyOrderToNewPopup
          onCopyPress={handleContextItemPress}
          currentStatus={order.shipmentStatus}
          currentOrderType={currentOrderType}
          isCopyEnabled={isCopyEnabled}
        />
      </Popover>
      <ListItem
        key={order.id}
        sx={itemStyles}
        onClick={clickHandler}
        onContextMenu={contextHandler}
      >
        <OrdersSectionListItemIcon
          status={order.status}
          label={alphabet[index]}
          isGrouped={false}
          type={order.type}
          isNew={order.isNew}
          isCurrent={order?.isCurrent}
          priority={priority}
        />

        <Grid container columns={13} sx={styles.content}>
          <Grid item mobile={7} alignSelf="center">
            <Typography variant="subtitle1">
              {order?.data?.proofType === ErrandDataProofTypes.smartLockIboxen
                ? t`iBoxen delivery`
                : order?.isTerminalReturn
                ? t`Terminal delivery`
                : order.dataTitle || t`Home delivery`}
            </Typography>
            <Typography>{order.subtitle}</Typography>
          </Grid>

          <Grid item mobile={1} alignSelf="center" pl={1}>
            {isTempered && <img src={temperedGoodsImg} alt="Tempered goods" />}
          </Grid>

          <Grid item mobile={3} alignSelf="center" textAlign="end">
            {!order?.data?.isManualCreated && (
              <Time time={order.time} isCollapsible={false} />
            )}
          </Grid>

          <Grid item mobile={2} alignSelf="center" textAlign="center" pl={1}>
            {!!unreadMessages && (
              <QueryParamsLink sx={{ zIndex: 100 }} onClick={clickHandler}>
                <UnreadLabel qty={unreadMessages} />
              </QueryParamsLink>
            )}
          </Grid>
          {order.isEditable && isCurrentOrFutureWorkshift && (
            <Box sx={styles.editButton}>
              <EditButton orderId={order.id} />
            </Box>
          )}
          {order.isEditable && !isCurrentOrFutureWorkshift && (
            <Box sx={styles.moveButton}>
              <MoveToCurrentWorkshiftButton
                workshiftId={selectedWorkshiftId as string}
                shipmentId={order.shipmentId}
                resourceId={selectedWorkshiftId as string}
              />
            </Box>
          )}
        </Grid>
      </ListItem>
    </>
  );
};

export default React.memo(OrdersSectionCollapsibleListItem);
