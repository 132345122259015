import { FC, useMemo, useState } from 'react';

import { FuelReportDetail } from 'components/screens/AdminHomeScreen/components/CompanyReports/components/FuelReportDetail';

import { FuelReportTable } from './components/FuelReportTable';
import { TireReportDetail } from './components/TireReportDetail';
import { TireReportTable } from './components/TireReportTable';

export const CompanyReports: FC = () => {
  const [selectedTireReportId, setSelectedTireReportId] = useState<
    number | null
  >(null);
  const [selectedFuelReportId, setSelectedFuelReportId] = useState<
    string | null
  >(null);
  const [currentReport, setCurrentReport] = useState<string>('1');

  console.log(selectedFuelReportId);

  const tireReportContent = useMemo(() => {
    if (!selectedTireReportId) {
      return (
        <TireReportTable
          setCurrentReport={setCurrentReport}
          currentReport={currentReport}
          setCurrentFeedbackId={setSelectedTireReportId}
        />
      );
    }

    return (
      <TireReportDetail
        selectedId={selectedTireReportId}
        setSelectedId={setSelectedTireReportId}
      />
    );
  }, [
    selectedTireReportId,
    setSelectedTireReportId,
    currentReport,
    setCurrentReport,
  ]);

  const fuelReportContent = useMemo(() => {
    if (!selectedFuelReportId) {
      return (
        <FuelReportTable
          setCurrentReport={setCurrentReport}
          currentReport={currentReport}
          setCurrentFuelReportId={setSelectedFuelReportId}
        />
      );
    }

    return (
      <FuelReportDetail
        selectedId={selectedFuelReportId}
        setSelectedId={setSelectedFuelReportId}
      />
    );
  }, [
    selectedFuelReportId,
    setSelectedFuelReportId,
    currentReport,
    setCurrentReport,
  ]);

  const content = useMemo(() => {
    if (currentReport === '1') {
      return tireReportContent;
    }

    if (currentReport === '2') {
      return fuelReportContent;
    }
    return null;
  }, [currentReport, tireReportContent, fuelReportContent]);

  return content;
};
