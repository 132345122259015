import { StylesProps } from 'types/common';

export const styles: StylesProps = {
  copyContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 0 15px 0',
    // border: '1px solid grey',
  },

  itemContainer: {
    width: '100%',
    padding: '5px 10px 5px 10px',
    '&:hover': {
      bgcolor: 'custom.main15',
      cursor: 'pointer',
    },
  },

  borderedReply: {
    borderRadius: '6px',
    borderColor: 'custom.greyBlue',
    bgcolor: 'custom.greyLightBlue',
  },
};
