import { createTypedSelector } from 'store/utils';

export const getFuelOrderBy = createTypedSelector(
  (state) => state.fuelReport.orderBy,
);

export const getFuelOrder = createTypedSelector(
  (state) => state.fuelReport.order,
);

export const getFuelPage = createTypedSelector(
  (state) => state.fuelReport.page,
);

export const getFuelSize = createTypedSelector(
  (state) => state.fuelReport.size,
);

export const getFuelResourceFilter = createTypedSelector(
  (state) => state.fuelReport.resourceFilters,
);

export const getFuelDriverFilter = createTypedSelector(
  (state) => state.fuelReport.driverFilters,
);
export const getFuelLicenceFilter = createTypedSelector(
  (state) => state.fuelReport.licenceFilters,
);
